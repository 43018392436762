import * as yup from 'yup';

import { Field, Form, FormikContext, useFormik } from 'formik';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AccountContext, useAccount } from '../../../lib/account';
import { useRepo } from '../../../lib/repository';
import { Button, PrimaryButton } from '../../Buttons';
import Input from '../../inputs/Input';
import Textarea from '../../inputs/Textarea';
import AccountSettingsPageLayout from '../../layouts/AccountSettingsPage';
import { BravaCurrencyIcon } from '../../widgets/Coins';

const schema = yup
  .object({
    brava_allowance_player: yup.number().nullable().min(0),
    brava_allowance_manager: yup.number().nullable().min(0),
    brava_allowance_teamcaptain: yup.number().nullable().min(0),
    birthday_amount: yup.number().min(0),
    birthday_message: yup.string().when('birthday_amount', {
      is: (val: number) => val > 0,
      then: yup.string().required(),
    }),
    welcome_amount: yup.number().min(0),
    welcome_message: yup.string().when('welcome_amount', {
      is: (val: number) => val > 0,
      then: yup.string().required(),
    }),
    workanniv_amount: yup.number().min(0),
    workanniv_message: yup.string().when('workanniv_amount', {
      is: (val: number) => val > 0,
      then: yup.string().required(),
    }),
  })
  .required();

export const AccountBravaSettingsPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { refreshAccount } = useContext(AccountContext);
  const account = useAccount();
  const queryClient = useQueryClient();
  const repo = useRepo();

  const bravaRules = useQuery('brava-rules', () => {
    return repo.getBravaRules(account.id);
  });

  const initialValues = useMemo(
    () =>
      ({
        brava_allowance_player: account.brava_allowance_player,
        brava_allowance_manager: account.brava_allowance_manager,
        brava_allowance_teamcaptain: account.brava_allowance_teamcaptain,
        birthday_amount: bravaRules.data?.birthday_amount ?? '',
        birthday_message: bravaRules.data?.birthday_message ?? '',
        welcome_amount: bravaRules.data?.welcome_amount ?? '',
        welcome_message: bravaRules.data?.welcome_message ?? '',
        workanniv_amount: bravaRules.data?.workanniv_amount ?? '',
        workanniv_message: bravaRules.data?.workanniv_message ?? '',
      } as yup.InferType<typeof schema>),
    [account, bravaRules.data]
  );

  const mutation = useMutation((values: typeof initialValues) => {
    return Promise.all([
      repo.updateAccount(account.id, {
        brava_allowance_player: values.brava_allowance_player,
        brava_allowance_manager: values.brava_allowance_manager,
        brava_allowance_teamcaptain: values.brava_allowance_teamcaptain,
      }),
      repo.setBravaRules(account.id, {
        birthday_amount: values.birthday_amount,
        birthday_message: values.birthday_message,
        welcome_amount: values.welcome_amount,
        welcome_message: values.welcome_message,
        workanniv_amount: values.workanniv_amount,
        workanniv_message: values.workanniv_message,
      }),
    ]);
  }, {});

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values, form) => {
      mutation.mutate(values, {
        onSuccess: () => {
          refreshAccount();
          queryClient.invalidateQueries(['brava-rules']);
        },
        onError: () => {
          form.setSubmitting(false);
        },
      });
    },
  });

  // When the account changes, which happens after we've refreshed it, reset the form.
  useEffect(() => {
    formik.resetForm({ values: initialValues });
    formik.setSubmitting(false);
  }, [account, bravaRules.data]); // eslint-disable-line

  const canSubmit = formik.dirty && formik.isValid && !formik.isSubmitting;

  return (
    <AccountSettingsPageLayout>
      <FormikContext.Provider value={formik}>
        <Form className="flex flex-col flex-grow">
          <div className="space-y-8">
            <div className="">
              <h2 className="text-lg mb-4 font-medium">{t('monthlyAllowance')}</h2>
              <p className="max-w-prose my-4">{t('bravaAllowanceNote')}</p>
              <div className="w-96">
                <div>
                  <div className="grid grid-cols-2 gap-4 items-center">
                    <div>{t('player')}</div>
                    <div className="flex items-center gap-2">
                      <Field as={Input} type="number" min={0} name="brava_allowance_player" /> <BravaCurrencyIcon />
                    </div>
                    <div>{t('accountRoleManager')}</div>
                    <div className="flex items-center gap-2">
                      <Field as={Input} type="number" min={0} name="brava_allowance_manager" /> <BravaCurrencyIcon />
                    </div>
                    <div>{t('accountRoleTeamCaptain')}</div>
                    <div className="flex items-center gap-2">
                      <Field as={Input} type="number" min={0} name="brava_allowance_teamcaptain" /> <BravaCurrencyIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <h2 className="text-lg mb-4 font-medium">{t('automatedRecognitions')}</h2>
              <div className="space-y-6">
                <div>
                  <h3 className="text-base mb-4 font-medium">{t('bravaReason.birthdaysLabel')}</h3>
                  <p className="mb-4">{t('bravaBirthdaysNote')}</p>
                  <div className="space-y-4">
                    <div className="w-96">
                      <div className="grid grid-cols-2 gap-4 items-center">
                        <div>{t('amount')}</div>
                        <div>
                          <div className="w-48 flex items-center gap-2">
                            <Field as={Input} type="number" min={0} name="birthday_amount" /> <BravaCurrencyIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <div className="w-48">{t('message')}</div>
                      <div className="w-96">
                        <Field as={Textarea} name="birthday_message" rows={4} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h3 className="text-base mb-4 font-medium">{t('bravaReason.newTeammateLabel')}</h3>
                  <p className="mb-4">{t('bravaNewTeammateNote')}</p>
                  <div className="space-y-4">
                    <div className="w-96">
                      <div className="grid grid-cols-2 gap-4 items-center">
                        <div>{t('amount')}</div>
                        <div>
                          <div className="w-48 flex items-center gap-2">
                            <Field as={Input} type="number" min={0} name="welcome_amount" /> <BravaCurrencyIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <div className="w-48">{t('message')}</div>
                      <div className="w-96">
                        <Field as={Textarea} name="welcome_message" rows={4} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h3 className="text-base mb-4 font-medium">{t('bravaReason.workAnniversaryLabel')}</h3>
                  <p className="mb-4">{t('bravaWorkAnnivNote')}</p>
                  <div className="space-y-4">
                    <div className="w-96">
                      <div className="grid grid-cols-2 gap-4 items-center">
                        <div>{t('amount')}</div>
                        <div>
                          <div className="w-48 flex items-center gap-2">
                            <Field as={Input} type="number" min={0} name="workanniv_amount" /> <BravaCurrencyIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <div className="w-48">{t('message')}</div>
                      <div className="w-96">
                        <Field as={Textarea} name="workanniv_message" rows={4} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full border-t border-gray-100 mt-10 pt-4 flex flex-row-reverse items-end">
            <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
              {t('save')}
            </PrimaryButton>
            <Button onClick={() => formik.resetForm()}>{t('discard')}</Button>
          </div>
        </Form>
      </FormikContext.Provider>
    </AccountSettingsPageLayout>
  );
};
