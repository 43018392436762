import { BlobServiceClient } from '@azure/storage-blob';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import React, { useContext } from 'react';
import { LoggedInUser, TokenManager } from './auth';
import { flavour } from './flavour';
import { Levels } from './levels';
import {
  Account,
  AccountMember,
  AccountMembership,
  AccountPaymentType,
  AccountPolicy,
  AccountPolicyOverrides,
  AccountRole,
  AccountStoreInformation,
  BravaFeedEntry,
  BravaRules,
  DashboardUser,
  Domain,
  Item,
  ItemAuctionState,
  ItemPurchaseVariant,
  ItemRaffleStatus,
  ItemSchedule,
  ItemType,
  ItemVoucher,
  Job,
  Order,
  OrderState,
  Plan,
  Player,
  Product,
  RaffleEntry,
  RedemptionMethod,
  ReportOverview,
  Store,
  Team,
  TeamMarket,
  Transaction,
} from './types';

type BlobConfig = { host: string; container: string; blob_name: string; token: string };
export type BlobIngested = { container: string; blobName: string };

export type ContinuableResponse<TType, TExtra = unknown> = {
  continuation?: ContinuationToken;
  items: TType[];
  total: number;
} & TExtra;
export type ContinuationToken = string | null | undefined;
type ListenerCallback = (...args: any[]) => void;

export type PlayerUpdatable =
  | Partial<Pick<Player, 'firstname' | 'lastname' | 'email' | 'shipping'>>
  | { birthdate: Player['birthdate'] | null; start_date: Player['start_date'] | null }
  | { birthdate_private?: Player['birthdate_private']; start_date_private?: Player['start_date_private'] };

export type StoreContentItem =
  | ({
      id: string;
      doc_type: 'store_item';
      type: ItemType;
      name: string;
      start_time: number;
      end_time: number;
      image_url: string;
      thumbnail_url: string;
      num_items: number; // The number of items available.
    } & ({
      sku: Item['sku'];
    } & (
      | {
          type: ItemType.Raffle;
          raffle_status: ItemRaffleStatus;
          num_purchased: number; // The number of entries purchased.
          num_participants: number; // The number of participants.
        }
      | {
          type: ItemType.Auction;
          auction_state: ItemAuctionState;
          num_bids: number; // The number of bids.
          num_participants: number; // The number of bidders.
        }
      | {
          type: ItemType.Purchase;
          num_purchased: number; // The number of times purchased.
        }
      | {
          type: ItemType.Sweepstakes;
          num_purchased: number; // The number of entries purchased.
          num_participants: number; // The number of participants.
        }
      | {
          type: ItemType.Contribution;
          num_coins: number;
        }
    )))
  | {
      id: string;
      doc_type: 'product';
      name: string;
      start_time: number;
      end_time: number;
      image_url: string;
      num_items: number; // The number of items available.
      num_purchased: number; // The number of items purchased.
    };

export type BravaFeedReturn = ContinuableResponse<
  BravaFeedEntry,
  {
    players: Pick<Player, 'id' | 'firstname' | 'lastname' | 'birthdate_private' | 'start_date_private'>[];
    users: Pick<DashboardUser, 'id' | 'name'>[];
  }
>;

export enum RepositoryEvent {
  AuthenticationError = 'AuthenticationError',
}

export interface ObservableRepository {
  addListener: (type: RepositoryEvent, callback: ListenerCallback) => void;
  removeListener: (type: RepositoryEvent, callback: ListenerCallback) => void;
}

export interface Repository {
  acceptApp: (
    accountId: string,
    clientId: string,
    redirectUri: string,
    responseType: string,
    state: string
  ) => Promise<{ redirect_url: string }>;
  validateApp: (clientId: string, redirectUri: string, responseType: string) => Promise<{ name: string; auto_accept: boolean }>;

  acceptInvitation: (id: string, secret: string) => Promise<{ account_id: string }>;
  acceptOrder: (orderId: string, message: string, itemName: string) => Promise<void>;
  addStoresToTeam: (storeIds: string[], teamId: string) => Promise<void>;
  addTeamsToStore: (teamIds: string[], storeId: string) => Promise<void>;
  awardBrava: (playerId: string, amount: number, reason: string, comment: string, message?: string) => Promise<void>;
  awardCoins: (playerId: string, coins: number, message?: string, reason?: string) => Promise<void>;
  awardTickets: (playerId: string, amount: number) => Promise<void>;

  commitDrawWinners: (itemId: string) => Promise<void>;
  completeAccountSubscriptionViaStripeCheckout: (accountId: string, sessionId: string) => Promise<Account>;
  countPendingOrders: (accountId: string) => Promise<number>;
  countPotentiallyInactiveUsers: (accountId: string, since: number) => Promise<number>;
  createAccount: (
    name: string,
    paymentType: AccountPaymentType,
    numUsers: number,
    expiry: number,
    expiryStrict: boolean
  ) => Promise<Account>;
  createAccountCustomerPortalSession: (accountId: string) => Promise<{ session_url: string }>;
  createAccountWebhookPortalSession: (accountId: string) => Promise<{ url: string }>;
  createAccountSubscriptionViaStripeCheckout: (accountId: string, priceId: string) => Promise<{ session_id: string }>;
  createBravaFeedComment: (teamId: string, entryId: string, comment: string, amount?: number) => Promise<void>;
  createDomain: (id: string, name: string) => Promise<Domain>;
  createDomainAdmin: (domainId: string, email: string, name: string) => Promise<DashboardUser>;
  createItem: (storeId: string, data: any) => Promise<Item>;
  createItemVouchers: (itemId: string, vouchers: string[]) => Promise<void>;
  createOwnAccount: (name: string) => Promise<Account>;
  createPlayer: (teamId: string, data: PlayerUpdatable) => Promise<Player>;
  createProduct: (storeId: string, data: any) => Promise<Product>;
  createStore: (accountId: string, name: string) => Promise<Store>;
  createTeam: (accountId: string, name: string) => Promise<Team>;

  declineOrder: (orderId: string) => Promise<void>;
  deleteAccount: (accountId: string) => Promise<void>;
  deleteItem: (itemId: string) => Promise<void>;
  deleteItemVouchers: (itemId: string, voucherIds: number[]) => Promise<void>;
  deleteMember: (memberId: string) => Promise<void>;
  deletePlayer: (playerId: string) => Promise<void>;
  deleteProduct: (productId: string) => Promise<void>;
  deleteStore: (storeId: string) => Promise<void>;
  deleteTeam: (teamId: string) => Promise<void>;
  duplicateItem: (itemId: string, name: string) => Promise<Item>;
  duplicateProduct: (productId: string, name: string) => Promise<Product>;
  exportOrders: (
    accountId: string,
    filters?: {
      state?: string;
      method?: string;
    },
    orderBy?: string
  ) => Promise<Blob>;
  exportPlayers: (
    accountId: string,
    filters?: {
      term?: string;
    },
    orderBy?: string
  ) => Promise<Blob>;
  exportStoreContent: (
    storeId: string,
    filters?: { term?: string; type?: ItemType; status?: ItemSchedule[] },
    orderBy?: string
  ) => Promise<Blob>;
  exportStoreTransactions: (
    storeId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string
  ) => Promise<Blob>;
  exportTeamPlayers: (
    teamId: string,
    filters?: {
      term?: string;
    },
    orderBy?: string
  ) => Promise<Blob>;
  exportTeamTransactions: (
    teamId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string
  ) => Promise<Blob>;
  exportTransactions: (
    accountId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string
  ) => Promise<Blob>;

  getAccount: (accountId: string) => Promise<Account>;
  getAccountAvailablePlans: (accountId: string) => Promise<Plan[]>;
  getAccountLevelsBlobAccess: (
    accountId: string,
    levels: { level: number; extension: string }[]
  ) => Promise<{ level: number; blob_config: BlobConfig }[]>;
  getAccountLevelsPlayerCount: (accountId: string, thresholds: number[]) => Promise<{ coins: number; players: number }[]>;
  getAccountStoreInformation: (accountId: string) => Promise<AccountStoreInformation>;
  getAccountMembership: (accountId: string) => Promise<AccountMembership>;

  getApiToken: (
    accountId: string,
    generate?: boolean
  ) => Promise<{ endpoint: string; apitoken: string; apitoken_hint: string } | null>;

  getBillingPlans: () => Promise<{ id: string; name: string; policy: AccountPolicy }[]>;
  getBravaFeed: (teamId: string, continuation?: ContinuationToken) => Promise<BravaFeedReturn>;
  getBravaRules: (accountId: string) => Promise<BravaRules>;
  getBrandingBlobAccess: (
    accountId: string,
    extensions: { icon_double?: string; logo?: string; tickets_icon?: string }
  ) => Promise<{
    logo: BlobConfig;
    icon_double: BlobConfig;
    tickets_icon: BlobConfig;
  }>;

  getDomains: () => Promise<Domain[]>;
  getDomainAdmins: (domainId: string) => Promise<DashboardUser[]>;
  getDrawCandidates: (itemId: string) => Promise<Pick<Player, 'id' | 'firstname' | 'lastname' | 'email'>[] | null>;

  getInvitationSummary: (
    id: string,
    secret: string
  ) => Promise<{
    account_name: string;
    invited_by_name: string;
    role: AccountRole;
  }>;

  getItem: (itemId: string) => Promise<Item>;
  getItemEntries: (
    itemId: string,
    orderBy?: string,
    pageSize?: number,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<RaffleEntry, { users: Pick<Player, 'id' | 'firstname' | 'lastname'>[] }>>;
  getItemFileDownloadUrl: (itemId: string) => Promise<string>;
  getItemTransactions: (
    itemId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string,
    pageSize?: number,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<Transaction, { users: Pick<Player, 'id' | 'firstname' | 'lastname'>[] }>>;
  getItemVouchers: (
    itemId: string
  ) => Promise<
    ContinuableResponse<
      ItemVoucher,
      { users: Pick<Player, 'id' | 'firstname' | 'lastname' | 'school_id' | 'store_ids_interacted_with'>[] }
    >
  >;

  getMe: (forceRefetch?: boolean) => Promise<LoggedInUser>;
  getMyAccounts: (searchTerm?: string, continuation?: ContinuationToken) => Promise<ContinuableResponse<Account>>;

  getMember: (memberId: string) => Promise<AccountMember>;
  getMembers: (
    accountId: string,
    filters?: {
      term?: string;
    },
    orderBy?: string,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<AccountMember>>;

  getOrders: (
    accountId: string,
    filters?: {
      method?: RedemptionMethod;
      state?: OrderState;
    },
    orderBy?: string,
    pageSize?: number,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<Order>>;

  getPlatforms: () => Promise<{ name: string; id: string }[]>;

  getPlayers: (
    accountId: string,
    filters?: {
      term?: string;
    },
    orderBy?: string,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<Player>>;

  getPlayer: (playerId: string) => Promise<Player>;

  getPlayerOrders: (
    playerId: string,
    filters?: {},
    orderBy?: string,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<Order>>;
  getPlayerStoreLoginUrl: (playerId: string) => Promise<{ url: string }>;
  getPlayerTransactions: (
    playerId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string,
    pageSize?: number,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<Transaction>>;

  getProduct: (productId: string) => Promise<Product>;
  getProductItems: (productId: string) => Promise<ItemPurchaseVariant[]>;
  getProductTransactions: (
    productId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string,
    pageSize?: number,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<Transaction>>;

  getPurgeInactiveUsersJobHistory: (accountId: string) => Promise<Job[]>;
  getRecentAccounts: () => Promise<Account[]>;
  getReportOverview: (
    accountId: string,
    filters?: {
      teamIds?: string[];
      dateFrom?: number;
      dateTo?: number;
    }
  ) => Promise<ReportOverview>;
  getReportDetailed: (
    accountId: string,
    reportId: string,
    timestamps: number[],
    filters?: {
      teamIds?: string[];
    }
  ) => Promise<{ range: [number, number]; value: number }[]>;

  getStores: (
    accountId: string,
    filters?: {
      term?: string;
    },
    orderBy?: string,
    pageSize?: number,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<Store>>;

  getStore: (storeId: string) => Promise<Store>;

  getStoreContent: (
    storeId: string,
    filters?: { term?: string; type?: ItemType; status?: ItemSchedule[] },
    orderBy?: string,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<StoreContentItem>>;

  getStoreTeams: (
    storeId: string,
    filters?: { term?: string },
    orderBy?: string,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<Team>>;

  getStoreTransactions: (
    storeId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<Transaction, { users: { id: string; firstname: string; lastname: string }[] }>>;

  getSvsLeaderboard: (accountId: string) => Promise<
    {
      rank: number;
      value: number;
      section: {
        id: string;
        name: string;
      };
    }[]
  >;

  getTeams: (
    accountId: string,
    filters?: {
      term?: string;
    },
    orderBy?: string,
    pageSize?: number,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<Team>>;

  getTeam: (teamId: string) => Promise<Team>;
  getTeamCoinsImportBlobAccess: (teamId: string) => Promise<BlobConfig>;
  getTeamCoinsImportJobHistory: (teamId: string) => Promise<Job[]>;
  getTeamLeaderboard: (teamId: string) => Promise<
    {
      rank: number;
      value: number;
      user: {
        id: string;
        firstname: string;
        lastname: string;
      };
    }[]
  >;
  getTeamMarket: (teamId: string) => Promise<TeamMarket>;
  getTeamPlayers: (
    teamId: string,
    filters?: {
      term?: string;
    },
    orderBy?: string,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<Player>>;
  getTeamStores: (
    teamId: string,
    filters?: { term?: string },
    orderBy?: string,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<Store>>;
  getTeamTransactions: (
    teamId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<Transaction, { users: { id: string; firstname: string; lastname: string }[] }>>;

  getTransactions: (
    accountId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string,
    continuation?: ContinuationToken
  ) => Promise<ContinuableResponse<Transaction, { users: { id: string; firstname: string; lastname: string }[] }>>;

  importCoinsFromCsv: (teamId: string, blobName: string) => Promise<void>;
  ingestFile: (file: File, blob: BlobConfig, options?: { cacheControl?: string }) => Promise<BlobIngested>;
  inviteMember: (
    accountId: string,
    email: string,
    role: string,
    teamIds: string[],
    storeIds: string[]
  ) => Promise<{ outcome: 'invitation' } | { outcome: 'upgrade'; member: AccountMember }>;
  likeBravaFeedEntry: (teamId: string, entryId: string, like: boolean) => Promise<void>;
  pickDrawCandidates: (itemId: string, excludePlayerIds?: string[]) => Promise<void>;
  purgeInactiveUsers: (accountId: string, since: number) => Promise<void>;

  removeStoresFromTeam: (storeIds: string[], teamId: string) => Promise<void>;
  removeTeamsFromStore: (teamIds: string[], storeId: string) => Promise<void>;
  reportRecentlyAccessedAccount: (accountId: string) => Promise<string[]>;
  resetAccountSvsLeaderboard: (accountId: string) => Promise<void>;
  resetTeamLeaderboard: (teamId: string) => Promise<void>;

  setAccountBilling: (
    accountId: string,
    paymentType: AccountPaymentType,
    expiry: number,
    expiryStrict: boolean,
    plan?: string,
    overrides?: AccountPolicyOverrides
  ) => Promise<void>;

  setAccountLevels: (accountId: string, levels: Levels) => Promise<void>;

  setAccountPolicyOverrides: (accountId: string, overrides: AccountPolicyOverrides) => Promise<void>;

  setBranding: (
    accountId: string,
    files: {
      icon_double?: string | null;
      logo?: string | null;
      tickets_icon?: string | null;
    },
    has_poweredby?: boolean | null
  ) => Promise<void>;
  setBravaFeedEntryVisibility: (teamId: string, entryId: string, hidden: boolean) => Promise<void>;
  setBravaFeedEntryCommentVisibility: (teamId: string, entryId: string, commentId: string, hidden: boolean) => Promise<void>;
  setBravaRules: (accountId: string, data: Partial<BravaRules>) => Promise<void>;
  setPlayerTeam: (playerId: string, teamId: string) => Promise<void>;
  setTeamMarket: (teamId: string, categories: TeamMarket['categories']) => Promise<void>;

  updateAccount: (accountId: string, data: Partial<Account>) => Promise<Account>;
  updateAccountStoreInformation: (
    accountId: string,
    content: string,
    rules: AccountStoreInformation['rules'],
    l10n?: AccountStoreInformation['l10n']
  ) => Promise<void>;
  updateAccountSvsLeaderboardOptions: (accountId: string, data: { enabled: boolean; anonymous: boolean }) => Promise<void>;
  updateItem: (itemId: string, data: Partial<Item & { image_file: File; download_file: File }>) => Promise<Item>;
  updateMe: (data: { firstname?: string; lastname?: string }) => Promise<void>;
  updateMemberRole: (
    memberId: string,
    data: { role: AccountRole; store_ids: string[]; team_ids: string[] }
  ) => Promise<AccountMember>;
  updatePlayer: (playerId: string, data: PlayerUpdatable) => Promise<Player>;
  updateProduct: (productId: string, data: Partial<Product> & { image_file?: File }) => Promise<Product>;
  updateStore: (storeId: string, data: Partial<Store>) => Promise<Store>;
  updateTeam: (teamId: string, data: Partial<Team>) => Promise<void>;
  updateTeamLeaderboardOptions: (teamId: string, data: { enabled: boolean; anonymous: boolean }) => Promise<void>;
}

export const RepositoryContext = React.createContext<Repository>({} as Repository);

export const useRepo = () => {
  return useContext(RepositoryContext);
};

/**
 * User repository.
 */
export class UserRepository implements Repository, ObservableRepository {
  protected listeners: { [index: string]: ListenerCallback[] } = {};

  constructor(protected _axios: AxiosInstance, protected tokenManager: TokenManager) {}

  acceptApp = async (accountId: string, clientId: string, redirectUri: string, responseType: string, state: string) => {
    return (
      await this.put(`/_/admin-api/accounts/${accountId}/app-accept`, {
        state,
        client_id: clientId,
        redirect_uri: redirectUri,
        response_type: responseType,
      })
    ).data;
  };

  validateApp = async (clientId: string, redirectUri: string, responseType: string) => {
    return (
      await this.post(`/_/admin-api/app/validate`, {
        client_id: clientId,
        redirect_uri: redirectUri,
        response_type: responseType,
      })
    ).data;
  };

  acceptInvitation = async (id: string, secret: string) => {
    return (await this.put(`/_/admin-api/invitations/${id}/accept`, undefined, { params: { secret } })).data;
  };

  acceptOrder = async (orderId: string, message: string, itemName: string) => {
    const resp = await this.put(`/_/admin-api/orders/${orderId}/accept`, {
      message: message,
    });
    return resp.data;
  };

  addStoresToTeam = async (storeIds: string[], teamId: string) => {
    await this.patch(`/_/admin-api/teams/${teamId}/stores`, { add: storeIds });
  };

  addTeamsToStore = async (teamIds: string[], storeId: string) => {
    await this.patch(`/_/admin-api/stores/${storeId}/teams`, { add: teamIds });
  };

  awardBrava = async (playerId: string, amount: number, reason: string, comment: string, message?: string) => {
    await this.post(`/_/admin-api/players/${playerId}/brava`, {
      amount,
      comment,
      message,
      reason,
    });
  };
  awardCoins = async (playerId: string, coins: number, message?: string, reason?: string) => {
    await this.post(`/_/admin-api/players/${playerId}/balance`, {
      coins: coins,
      message: message,
      reason: reason
        ? {
            string: reason,
          }
        : undefined,
    });
  };

  awardTickets = async (playerId: string, amount: number) => {
    await this.post(`/_/admin-api/players/${playerId}/tickets`, {
      amount: amount,
    });
  };

  commitDrawWinners = async (itemId: string) => {
    await this.post(`/_/admin-api/items/${itemId}/draw/winners`);
  };

  completeAccountSubscriptionViaStripeCheckout = async (accountId: string, sessionId: string) => {
    return (
      await this.post(`/_/admin-api/accounts/${accountId}/stripe-checkout/callback`, {
        session_id: sessionId,
      })
    ).data;
  };

  countPotentiallyInactiveUsers = async (accountId: string, since: number) => {
    return (
      await this.get(`/_/admin-api/accounts/${accountId}/players/potentially-inactive/count`, {
        params: { since },
      })
    ).data;
  };

  countPendingOrders = async (accountId: string) => {
    const resp = await this.get<number>(`/_/admin-api/accounts/${accountId}/orders/count`);
    return resp.data;
  };

  createAccount = async (
    name: string,
    paymentType: AccountPaymentType,
    numUsers: number,
    expiry: number,
    expiryStrict: boolean
  ) => {
    return (
      await this.post<Account>(`/_/admin-api/accounts`, {
        account_name: name,
        account_type: flavour,
        expiry,
        expiry_strict: expiryStrict,
        payment_type: paymentType,
        max_users: numUsers,
      })
    ).data;
  };

  createAccountCustomerPortalSession = async (accountId: string) => {
    return (await this.post(`/_/admin-api/accounts/${accountId}/stripe-customer-portal`)).data;
  };

  createAccountWebhookPortalSession = async (accountId: string) => {
    return (await this.post(`/_/admin-api/accounts/${accountId}/svix-app-portal`)).data;
  };

  createAccountSubscriptionViaStripeCheckout = async (accountId: string, priceId: string) => {
    return (await this.post(`/_/admin-api/accounts/${accountId}/stripe-checkout`, { price_id: priceId })).data;
  };

  createBravaFeedComment = async (teamId: string, entryId: string, comment: string, amount?: number) => {
    await this.post(`/_/admin-api/teams/${teamId}/brava-feed/${entryId}/comments`, { comment, amount });
  };

  createDomain = async (id: string, name: string) => {
    return (await this.post(`/_/admin-api/domains`, { id, name })).data;
  };

  createDomainAdmin = async (domainId: string, email: string, name: string) => {
    return (await this.post(`/_/admin-api/domains/${domainId}/admins`, { email, name })).data;
  };

  createItem = async (storeId: string, data: any) => {
    const { image_file, download_file, ...itemData } = data;
    const item = (await this.post(`/_/admin-api/stores/${storeId}/items`, itemData)).data;

    let promises = [
      this.ingestHelper('image_url', image_file, (ext) => this.getItemBlobWriteAccess(item.id, 'image', ext)),
      this.ingestHelper('download_url', download_file, (ext) => this.getItemBlobWriteAccess(item.id, 'download', ext)),
    ];

    const files = await Promise.all(promises);
    const filesData = _.merge({}, ...files);
    if (!_.isEmpty(filesData)) {
      return await this.updateItemInternal(item.id, filesData);
    }

    return item;
  };

  createItemVouchers = async (itemId: string, vouchers: string[]) => {
    await this.post(`/_/admin-api/items/${itemId}/vouchers`, vouchers);
  };

  createOwnAccount = async (name: string) => {
    return (await this.post<Account>(`/_/admin-api/me/accounts`, { name })).data;
  };

  createPlayer = async (teamId: string, data: PlayerUpdatable) => {
    return (await this.post(`/_/admin-api/teams/${teamId}/players`, data)).data;
  };

  createProduct = async (storeId: string, data: any) => {
    const { image_file, download_file, ...productData } = data;
    const product = (await this.post<Product>(`/_/admin-api/stores/${storeId}/products`, productData)).data;

    let promises = [this.ingestHelper('image_url', image_file, (ext) => this.getProductBlobWriteAccess(product.id, 'image', ext))];

    const files = await Promise.all(promises);
    const filesData = _.merge({}, ...files);
    if (!_.isEmpty(filesData)) {
      return await this.updateProduct(product.id, {
        ...filesData,
        variants: product.variants.map((v) => ({ item_id: v.item_id })),
      });
    }

    return product;
  };

  createStore = async (accountId: string, name: string) => {
    return (await this.post(`/_/admin-api/accounts/${accountId}/stores`, { name })).data;
  };

  createTeam = async (accountId: string, name: string) => {
    return (await this.post(`/_/admin-api/accounts/${accountId}/teams`, { name })).data;
  };

  declineOrder = async (orderId: string) => {
    await this.put(`/_/admin-api/orders/${orderId}/decline`);
  };

  deleteAccount = async (accountId: string) => {
    return (await this.delete(`/_/admin-api/accounts/${accountId}`)).data;
  };

  deleteItem = async (itemId: string) => {
    return (await this.delete(`/_/admin-api/items/${itemId}`)).data;
  };

  deleteItemVouchers = async (itemId: string, voucherIds: number[]) => {
    return (await this.delete(`/_/admin-api/items/${itemId}/vouchers`, { data: voucherIds })).data;
  };

  deleteMember = async (memberId: string) => {
    return (await this.delete(`/_/admin-api/members/${memberId}`)).data;
  };

  deletePlayer = async (playerId: string) => {
    await this.delete(`/_/admin-api/players/${playerId}`);
  };

  deleteProduct = async (productId: string) => {
    return (await this.delete(`/_/admin-api/products/${productId}`)).data;
  };

  deleteStore = async (storeId: string) => {
    return (await this.delete(`/_/admin-api/stores/${storeId}`)).data;
  };

  deleteTeam = async (teamId: string) => {
    return (await this.delete(`/_/admin-api/teams/${teamId}`)).data;
  };

  duplicateItem = async (itemId: string, name: string) => {
    return (await this.post(`/_/admin-api/items/${itemId}/duplicate`, { name })).data;
  };

  duplicateProduct = async (productId: string, name: string) => {
    return (await this.post(`/_/admin-api/products/${productId}/duplicate`, { name })).data;
  };

  exportOrders = async (
    accountId: string,
    filters?: {
      state?: string;
      method?: string;
    },
    orderBy?: string
  ) => {
    const resp = await this.get<Blob>(`/_/admin-api/accounts/${accountId}/orders.csv`, {
      params: {
        ...filters,
        orderby: orderBy,
      },
      responseType: 'blob',
    });
    return resp.data;
  };

  exportPlayers = async (
    accountId: string,
    filters?: {
      term?: string;
    },
    orderBy?: string
  ) => {
    const resp = await this.get<Blob>(`/_/admin-api/accounts/${accountId}/players.csv`, {
      params: {
        term: filters?.term,
        orderby: orderBy,
      },
      responseType: 'blob',
    });
    return resp.data;
  };

  exportStoreContent = async (
    storeId: string,
    filters?: { term?: string; type?: ItemType; status?: ItemSchedule[] },
    orderBy?: string
  ) => {
    const resp = await this.get<Blob>(
      `/_/admin-api/stores/${storeId}/content.csv`,

      {
        params: {
          ...filters,
          orderby: orderBy,
        },
        responseType: 'blob',
      }
    );
    return resp.data;
  };

  exportStoreTransactions = async (
    storeId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string
  ) => {
    const resp = await this.get<Blob>(
      `/_/admin-api/stores/${storeId}/transactions.csv`,

      {
        params: {
          type: filters?.type,
          details: filters?.details,
          date_from: filters?.dateFrom,
          date_to: filters?.dateTo,
        },
        responseType: 'blob',
      }
    );
    return resp.data;
  };

  exportTeamPlayers = async (
    teamId: string,
    filters?: {
      term?: string;
    },
    orderBy?: string
  ) => {
    const resp = await this.get<Blob>(`/_/admin-api/teams/${teamId}/players.csv`, {
      params: {
        term: filters?.term,
        orderby: orderBy,
      },
      responseType: 'blob',
    });
    return resp.data;
  };

  exportTeamTransactions = async (
    teamId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string
  ) => {
    const resp = await this.get<Blob>(
      `/_/admin-api/teams/${teamId}/transactions.csv`,

      {
        params: {
          type: filters?.type,
          details: filters?.details,
          date_from: filters?.dateFrom,
          date_to: filters?.dateTo,
        },
        responseType: 'blob',
      }
    );
    return resp.data;
  };

  exportTransactions = async (
    accountId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string
  ) => {
    const resp = await this.get<Blob>(
      `/_/admin-api/accounts/${accountId}/transactions.csv`,

      {
        params: {
          type: filters?.type,
          details: filters?.details,
          date_from: filters?.dateFrom,
          date_to: filters?.dateTo,
        },
        responseType: 'blob',
      }
    );
    return resp.data;
  };

  getAccount = async (accountId: string) => {
    return (await this.get(`/_/admin-api/accounts/${accountId}`, {})).data;
  };

  getAccountAvailablePlans = async (accountId: string) =>
    (await this.get(`/_/admin-api/accounts/${accountId}/available-plans`)).data;

  getAccountLevelsBlobAccess = async (accountId: string, levels: { level: number; extension: string }[]) =>
    (await this.post(`/_/admin-api/accounts/${accountId}/levels/blob-access`, levels)).data;

  getAccountLevelsPlayerCount = async (accountId: string, thresholds: number[]) =>
    (await this.get(`/_/admin-api/accounts/${accountId}/levels-player-count`, { params: { thresholds: thresholds.join(',') } }))
      .data;

  getAccountMembership = async (accountId: string) => {
    return (await this.get(`/_/admin-api/me/accounts/${accountId}/membership`, {})).data;
  };

  getAccountStoreInformation = async (accountId: string) => {
    return (await this.get(`/_/admin-api/accounts/${accountId}/store-information`)).data;
  };

  getApiToken = async (accountId: string, generate: boolean = false) => {
    if (generate) {
      return (await this.put(`/_/admin-api/accounts/${accountId}/api-token`)).data;
    }
    return (await this.get(`/_/admin-api/accounts/${accountId}/api-token`)).data;
  };

  getBillingPlans = async () => (await this.get('/_/admin-api/billing/plans')).data;

  getBrandingBlobAccess = async (
    accountId: string,
    extensions: {
      icon_double?: string;
      logo?: string;
      tickets_icon?: string;
    }
  ) => {
    return (
      await this.post(`/_/admin-api/accounts/${accountId}/branding/blob-access`, {
        icon_double_extension: extensions.icon_double,
        logo_extension: extensions.logo,
        tickets_icon_extension: extensions.tickets_icon,
      })
    ).data;
  };

  getBravaFeed = async (teamId: string, continuation?: ContinuationToken) => {
    return (
      await this.get<BravaFeedReturn>(`/_/admin-api/teams/${teamId}/brava-feed`, {
        params: {
          continuation: continuation ?? undefined,
        },
      })
    ).data;
  };

  getBravaRules = async (accountId: string) => {
    return (await this.get(`/_/admin-api/accounts/${accountId}/brava-rules`)).data;
  };

  getDomains = async () => {
    return (await this.get(`/_/admin-api/domains`)).data;
  };

  getDomainAdmins = async (domainId: string) => {
    return (await this.get(`/_/admin-api/domains/${domainId}/admins`)).data;
  };

  getDrawCandidates = async (itemId: string) => {
    const resp = await this.get(`/_/admin-api/items/${itemId}/draw/candidates`);
    if (resp.status === 204) {
      return null;
    }
    return resp.data;
  };

  getInvitationSummary = async (id: string, secret: string) => {
    return (await this.get(`/_/admin-api/invitations/${id}/summary`, { params: { secret } })).data;
  };

  getItem = async (itemId: string) => {
    return (await this.get(`/_/admin-api/items/${itemId}`)).data;
  };

  getItemEntries = async (itemId: string, orderBy?: string, pageSize?: number, continuation?: ContinuationToken) => {
    return (
      await this.get(`/_/admin-api/items/${itemId}/entries`, {
        params: {
          orderby: Boolean(orderBy) ? orderBy : undefined,
          continuation: continuation || undefined,
          pagesize: pageSize,
        },
      })
    ).data;
  };

  getItemFileDownloadUrl = async (itemId: string) => {
    return (await this.get<string>(`/_/admin-api/items/${itemId}/download-redemption-file-url`)).data;
  };

  getItemTransactions = async (
    itemId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string,
    pageSize?: number,
    continuation?: ContinuationToken
  ) => {
    const resp = await this.get(`/_/admin-api/items/${itemId}/transactions`, {
      params: {
        type: filters?.type,
        details: filters?.details,
        date_from: filters?.dateFrom,
        date_to: filters?.dateTo,
        continuation: continuation || undefined,
        pagesize: pageSize,
      },
    });
    return resp.data;
  };

  getItemVouchers = async (itemId: string) => (await this.get(`/_/admin-api/items/${itemId}/vouchers`)).data;

  protected getItemBlobWriteAccess = async (itemId: string, type: 'image' | 'download', extension: string) => {
    return (await this.post<BlobConfig>(`/_/admin-api/items/${itemId}/blob-access`, { type, extension })).data;
  };

  getMe = async (forceRefetch: boolean = false) => {
    try {
      if (forceRefetch) throw new Error('Using cache is not allowed.');
      return await this.tokenManager.getUser();
    } catch (err) {
      return (await this.get('/_/admin-api/me')).data;
    }
  };

  getMyAccounts = async (searchTerm?: string, continuation?: ContinuationToken) => {
    const resp = await this.get('/_/admin-api/me/accounts', {
      params: {
        term: searchTerm && searchTerm !== '' ? searchTerm : undefined,
        continuation: continuation || undefined,
      },
    });
    return resp.data;
  };

  getMember = async (adminId: string) => {
    return (await this.get(`/_/admin-api/members/${adminId}`, {})).data;
  };

  getMembers = async (
    accountId: string,
    filters?: {
      term?: string;
    },
    orderBy?: string,
    continuation?: ContinuationToken
  ) => {
    return (
      await this.get(`/_/admin-api/accounts/${accountId}/members`, {
        params: {
          term: typeof filters?.term === 'string' && filters.term !== '' ? filters.term : undefined,
          orderby: Boolean(orderBy) ? orderBy : undefined,
          continuation: continuation || undefined,
          pagesize: 20,
        },
      })
    ).data;
  };

  getOrders = async (
    accountId: string,
    filters?: {
      method?: RedemptionMethod;
      state?: OrderState;
    },
    orderBy?: string,
    pageSize?: number,
    continuation?: ContinuationToken
  ) => {
    const resp = await this.get(`/_/admin-api/accounts/${accountId}/orders`, {
      params: {
        continuation: continuation || undefined,
        orderby: orderBy,
        state: filters?.state,
        method: filters?.method,
        pagesize: pageSize,
      },
    });
    return resp.data;
  };

  getPlatforms = async () => {
    return (await this.get(`/_/admin-api/platforms`)).data;
  };

  getPlayers = async (
    accountId: string,
    filters?: {
      term?: string;
    },
    orderBy?: string,
    continuation?: ContinuationToken
  ) => {
    const resp = await this.get(`/_/admin-api/accounts/${accountId}/players`, {
      params: {
        account_id: accountId,
        term: typeof filters?.term === 'string' && filters.term !== '' ? filters.term : undefined,
        orderby: Boolean(orderBy) ? orderBy : undefined,
        continuation: continuation || undefined,
        pagesize: 20,
      },
    });
    return resp.data;
  };

  getPlayer = async (playerId: string): Promise<Player> => {
    const resp = await this.get(`/_/admin-api/players/${playerId}`, {});
    return resp.data;
  };

  getPlayerOrders = async (playerId: string, filters?: {}, orderBy?: string, continuation?: ContinuationToken) => {
    const resp = await this.get(`/_/admin-api/players/${playerId}/orders`, {
      params: {
        continuation: continuation || undefined,
        pagesize: 20,
      },
    });
    return resp.data;
  };

  getPlayerStoreLoginUrl = async (playerId: string) => (await this.post(`/_/admin-api/players/${playerId}/store-login-url`)).data;

  getPlayerTransactions = async (
    playerId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string,
    pageSize?: number,
    continuation?: ContinuationToken
  ) => {
    return (
      await this.get(`/_/admin-api/players/${playerId}/transactions`, {
        params: {
          type: filters?.type,
          details: filters?.details,
          date_from: filters?.dateFrom,
          date_to: filters?.dateTo,
          continuation: continuation || undefined,
          pagesize: pageSize,
        },
      })
    ).data;
  };

  getProduct = async (productId: string) => {
    return (await this.get(`/_/admin-api/products/${productId}`)).data;
  };

  getProductItems = async (productId: string) => (await this.get(`/_/admin-api/products/${productId}/items`)).data;

  getProductTransactions = async (
    productId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string,
    pageSize?: number,
    continuation?: ContinuationToken
  ) => {
    const resp = await this.get(`/_/admin-api/products/${productId}/transactions`, {
      params: {
        type: filters?.type,
        details: filters?.details,
        date_from: filters?.dateFrom,
        date_to: filters?.dateTo,
        continuation: continuation || undefined,
        pagesize: pageSize,
      },
    });
    return resp.data;
  };

  protected getProductBlobWriteAccess = async (productId: string, type: 'image', extension: string) => {
    return (await this.post<BlobConfig>(`/_/admin-api/products/${productId}/blob-access`, { type, extension })).data;
  };

  getPurgeInactiveUsersJobHistory = async (accountId: string) => {
    return (await this.get(`/_/admin-api/accounts/${accountId}/player-purges`)).data;
  };

  getRecentAccounts = async () => {
    return (await this.get(`/_/admin-api/me/recent-accounts`)).data;
  };

  getReportOverview = async (
    accountId: string,
    filters?: {
      teamIds?: string[];
      dateFrom?: number;
      dateTo?: number;
    }
  ) =>
    (
      await this.get(`/_/admin-api/accounts/${accountId}/reports/overview`, {
        params: { team_ids: filters?.teamIds, date_from: filters?.dateFrom, date_to: filters?.dateTo },
      })
    ).data;

  getReportDetailed = async (
    accountId: string,
    reportId: string,
    timestamps: number[],
    filters?: {
      teamIds?: string[];
    }
  ) =>
    (
      await this.get(`/_/admin-api/accounts/${accountId}/reports/detailed`, {
        params: {
          report_id: reportId,
          timestamps,
          team_ids: filters?.teamIds,
        },
      })
    ).data;

  getStores = async (
    accountId: string,
    filters?: { term?: string },
    orderBy?: string,
    pageSize: number = 20,
    continuation?: ContinuationToken
  ) => {
    const resp = await this.get(`/_/admin-api/accounts/${accountId}/stores`, {
      params: {
        term: typeof filters?.term === 'string' && filters.term !== '' ? filters.term : undefined,
        orderby: Boolean(orderBy) ? orderBy : undefined,
        continuation: continuation || undefined,
        pagesize: pageSize,
      },
    });
    return resp.data;
  };

  getStore = async (storeId: string) => {
    return (await this.get(`/_/admin-api/stores/${storeId}`, {})).data;
  };

  getStoreContent = async (
    storeId: string,
    filters?: { term?: string; type?: ItemType; status?: ItemSchedule[] },
    orderBy?: string,
    continuation?: ContinuationToken
  ) => {
    return (
      await this.get(`/_/admin-api/stores/${storeId}/content`, {
        params: {
          term: typeof filters?.term === 'string' && filters.term !== '' ? filters.term : undefined,
          type: filters?.type,
          status: filters?.status,
          orderby: Boolean(orderBy) ? orderBy : undefined,
          continuation: continuation || undefined,
          pagesize: 20,
        },
      })
    ).data;
  };

  getStoreTeams = async (storeId: string, filters?: { term?: string }, orderBy?: string, continuation?: ContinuationToken) => {
    return (
      await this.get(`/_/admin-api/stores/${storeId}/teams`, {
        params: {
          term: typeof filters?.term === 'string' && filters.term !== '' ? filters.term : undefined,
          orderby: Boolean(orderBy) ? orderBy : undefined,
          continuation: continuation || undefined,
          pagesize: 20,
        },
      })
    ).data;
  };

  getStoreTransactions = async (
    storeId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string,
    continuation?: ContinuationToken
  ) => {
    const resp = await this.get(`/_/admin-api/stores/${storeId}/transactions`, {
      params: {
        type: filters?.type,
        details: filters?.details,
        date_from: filters?.dateFrom,
        date_to: filters?.dateTo,
        continuation: continuation || undefined,
        pagesize: 20,
      },
    });
    return resp.data;
  };

  getSvsLeaderboard = async (accountId: string) => {
    return (await this.get(`/_/admin-api/accounts/${accountId}/leaderboard`, {})).data;
  };

  getTeams = async (
    accountId: string,
    filters?: {
      term?: string;
    },
    orderBy?: string,
    pageSize: number = 20,
    continuation?: ContinuationToken
  ) => {
    const resp = await this.get(`/_/admin-api/accounts/${accountId}/teams`, {
      params: {
        term: typeof filters?.term === 'string' && filters.term !== '' ? filters.term : undefined,
        orderby: Boolean(orderBy) ? orderBy : undefined,
        continuation: continuation || undefined,
        pagesize: pageSize,
      },
    });
    return resp.data;
  };

  getTeam = async (teamId: string) => {
    const resp = await this.get(`/_/admin-api/teams/${teamId}`, {});
    return resp.data;
  };

  getTeamCoinsImportBlobAccess = async (teamId: string) => {
    return (await this.post(`/_/admin-api/teams/${teamId}/coin-imports/blob-access`)).data;
  };

  getTeamCoinsImportJobHistory = async (teamId: string) => {
    return (await this.get(`/_/admin-api/teams/${teamId}/coin-imports`)).data;
  };

  getTeamLeaderboard = async (teamId: string) => {
    return (await this.get(`/_/admin-api/teams/${teamId}/leaderboard`, {})).data;
  };

  getTeamMarket = async (teamId: string) => {
    return (await this.get(`/_/admin-api/teams/${teamId}/market`, {})).data;
  };

  getTeamPlayers = async (
    teamId: string,
    filters?: {
      term?: string;
    },
    orderBy?: string,
    continuation?: ContinuationToken
  ) => {
    const resp = await this.get(`/_/admin-api/teams/${teamId}/players`, {
      params: {
        term: typeof filters?.term === 'string' && filters.term !== '' ? filters.term : undefined,
        orderby: Boolean(orderBy) ? orderBy : undefined,
        continuation: continuation || undefined,
        pagesize: 20,
      },
    });
    return resp.data;
  };

  getTeamStores = async (teamId: string, filters?: { term?: string }, orderBy?: string, continuation?: ContinuationToken) => {
    const resp = await this.get(`/_/admin-api/teams/${teamId}/stores`, {
      params: {
        term: typeof filters?.term === 'string' && filters.term !== '' ? filters.term : undefined,
        order_by: Boolean(orderBy) ? orderBy : undefined,
        continuation: continuation || undefined,
        pagesize: 20,
      },
    });
    return resp.data;
  };

  getTeamTransactions = async (
    teamId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string,
    continuation?: ContinuationToken
  ) => {
    const resp = await this.get(`/_/admin-api/teams/${teamId}/transactions`, {
      params: {
        type: filters?.type,
        details: filters?.details,
        date_from: filters?.dateFrom,
        date_to: filters?.dateTo,
        continuation: continuation || undefined,
        pagesize: 20,
      },
    });
    return resp.data;
  };

  getTransactions = async (
    accountId: string,
    filters?: {
      type?: string;
      details?: string;
      dateFrom?: number;
      dateTo?: number;
    },
    orderBy?: string,
    continuation?: ContinuationToken
  ) => {
    const resp = await this.get(`/_/admin-api/accounts/${accountId}/transactions`, {
      params: {
        type: filters?.type,
        details: filters?.details,
        date_from: filters?.dateFrom,
        date_to: filters?.dateTo,
        continuation: continuation || undefined,
        pagesize: 20,
      },
    });
    return resp.data;
  };

  importCoinsFromCsv = async (teamId: string, blobUrl: string) => {
    return (
      await this.post(`/_/admin-api/teams/${teamId}/coin-imports`, {
        blob_url: blobUrl,
      })
    ).data;
  };

  ingestFile = async (file: File, blob: BlobConfig, options?: { cacheControl?: string }) => {
    const service = new BlobServiceClient(`${blob.host}?${blob.token}`);
    const container = service.getContainerClient(blob.container);
    const blockBlobClient = container.getBlockBlobClient(blob.blob_name);
    await blockBlobClient.uploadData(file, {
      blobHTTPHeaders: {
        blobCacheControl: options?.cacheControl,
      },
    });
    return { container: blob.container, blobName: blob.blob_name };
  };

  protected async ingestHelper<TProperty extends string>(
    property: TProperty,
    file: File | null | undefined,
    blobAccessGetter: (fileExtension: string) => Promise<BlobConfig>
  ): Promise<Object | { [K in TProperty]: string }> {
    if (!file) return {};
    const ext = file.name.split('.').pop() || '';
    const blobConfig = await blobAccessGetter(ext);
    const blob = await this.ingestFile(file, blobConfig);
    return { [property]: `${blob.container}/${blob.blobName}` };
  }

  inviteMember = async (accountId: string, email: string, role: string, teamIds: string[], storeIds: string[]) => {
    return (
      await this.post(`/_/admin-api/accounts/${accountId}/members`, {
        email,
        role,
        team_ids: teamIds,
        store_ids: storeIds,
      })
    ).data;
  };

  likeBravaFeedEntry = async (teamId: string, entryId: string, like: boolean) => {
    if (like) {
      await this.post(`/_/admin-api/teams/${teamId}/brava-feed/${entryId}/like`);
    } else {
      await this.delete(`/_/admin-api/teams/${teamId}/brava-feed/${entryId}/like`);
    }
  };

  pickDrawCandidates = async (itemId: string, excludePlayerIds?: string[]) => {
    await this.put(`/_/admin-api/items/${itemId}/draw/candidates`, { exclude: excludePlayerIds });
  };

  purgeInactiveUsers = async (accountId: string, since: number) => {
    await this.post(`/_/admin-api/accounts/${accountId}/player-purges`, { since });
  };

  removeStoresFromTeam = async (storeIds: string[], teamId: string) => {
    await this.patch(`/_/admin-api/teams/${teamId}/stores`, { remove: storeIds });
  };

  removeTeamsFromStore = async (teamIds: string[], storeId: string) => {
    await this.patch(`/_/admin-api/stores/${storeId}/teams`, { remove: teamIds });
  };

  reportRecentlyAccessedAccount = async (accountId: string) => {
    return (await this.post<string[]>(`/_/admin-api/me/recent-accounts`, { account_id: accountId })).data;
  };

  resetAccountSvsLeaderboard = async (accountId: string) => {
    return (await this.put(`/_/admin-api/accounts/${accountId}/leaderboard/reset`)).data;
  };

  resetTeamLeaderboard = async (teamId: string) => {
    await this.put(`/_/admin-api/teams/${teamId}/leaderboard/reset`);
  };

  setAccountBilling = async (
    accountId: string,
    paymentType: AccountPaymentType,
    expiry: number,
    expiryStrict: boolean,
    plan?: string,
    overrides?: AccountPolicyOverrides
  ) => {
    await this.patch(`/_/admin-api/accounts/${accountId}/billing`, {
      payment_type: paymentType,
      expiry,
      expiry_strict: expiryStrict,
      plan,
      overrides,
    });
  };

  setAccountLevels = async (accountId: string, levels: Levels) => {
    await this.put(`/_/admin-api/accounts/${accountId}/levels`, levels);
  };

  setAccountPolicyOverrides = async (accountId: string, overrides: AccountPolicyOverrides) => {
    await this.put(`/_/admin-api/accounts/${accountId}/policy-overrides`, overrides);
  };

  setBranding = async (
    accountId: string,
    files: {
      icon_double?: string | null;
      logo?: string | null;
      tickets_icon?: string | null;
    },
    has_poweredby?: boolean | null
  ) => {
    await this.patch(`/_/admin-api/accounts/${accountId}/branding`, {
      icon_double_url: files?.icon_double || '',
      logo_url: files?.logo || '',
      remove_icon_double: files?.icon_double === null,
      remove_logo: files?.logo === null,
      remove_tickets_icon: files?.tickets_icon === null,
      tickets_icon_url: files?.tickets_icon || '',
      has_poweredby: has_poweredby,
    });
  };

  setBravaFeedEntryVisibility = async (teamId: string, entryId: string, hidden: boolean) => {
    await this.put(`/_/admin-api/teams/${teamId}/brava-feed/${entryId}/visibility`, { hidden });
  };

  setBravaFeedEntryCommentVisibility = async (teamId: string, entryId: string, commentId: string, hidden: boolean) => {
    await this.put(`/_/admin-api/teams/${teamId}/brava-feed/${entryId}/comments/${commentId}/visibility`, { hidden });
  };

  setBravaRules = async (accountId: string, data: Partial<BravaRules>) => {
    await this.patch(`/_/admin-api/accounts/${accountId}/brava-rules`, data);
  };

  setPlayerTeam = async (playerId: string, teamId: string) => {
    await this.put(`/_/admin-api/players/${playerId}/team`, { team_id: teamId });
  };

  setTeamMarket = async (teamId: string, categories: TeamMarket['categories']) => {
    await this.put(`/_/admin-api/teams/${teamId}/market`, { categories });
  };

  updateAccount = async (accountId: string, data: Partial<Account>) => {
    return (await this.patch(`/_/admin-api/accounts/${accountId}`, data)).data;
  };

  updateAccountStoreInformation = async (
    accountId: string,
    content: string,
    rules: AccountStoreInformation['rules'],
    l10n?: AccountStoreInformation['l10n']
  ) => {
    await this.put(`/_/admin-api/accounts/${accountId}/store-information`, { content, rules, l10n });
  };

  updateAccountSvsLeaderboardOptions = async (accountId: string, data: { enabled: boolean; anonymous: boolean }) => {
    return (await this.patch(`/_/admin-api/accounts/${accountId}/leaderboard`, data)).data;
  };

  updateItem = async (itemId: string, data: Partial<Item & { image_file: File; download_file: File }>) => {
    const { image_file, download_file, ...itemData } = data;

    // Update the item first as changes in its properties may be required to get a blob access.
    let item = await this.updateItemInternal(itemId, itemData);

    let promises = [
      this.ingestHelper('image_url', image_file, (ext) => this.getItemBlobWriteAccess(itemId, 'image', ext)),
      this.ingestHelper('download_url', download_file, (ext) => this.getItemBlobWriteAccess(itemId, 'download', ext)),
    ];

    const files = await Promise.all(promises);
    const filesData = _.merge({}, ...files);
    if (!_.isEmpty(filesData)) {
      return await this.updateItemInternal(item.id, filesData);
    }

    return item;
  };

  protected updateItemInternal = async (itemId: string, data: Partial<Item>) => {
    return (await this.patch(`/_/admin-api/items/${itemId}`, data)).data;
  };

  updateMe = async (data: { firstname?: string; lastname?: string }) => {
    await this.patch('/_/admin-api/me', data);
  };

  updateMemberRole = async (memberId: string, data: { role: string; store_ids: string[]; team_ids: string[] }) => {
    return (await this.patch(`/_/admin-api/members/${memberId}`, data)).data;
  };

  updatePlayer = async (playerId: string, data: PlayerUpdatable) => {
    const resp = await this.patch(`/_/admin-api/players/${playerId}`, data, {});
    return resp.data;
  };

  updateProduct = async (productId: string, data: Partial<Product> & { image_file?: File }) => {
    const { image_file, ...productData } = data;

    let promises = [this.ingestHelper('image_url', image_file, (ext) => this.getProductBlobWriteAccess(productId, 'image', ext))];

    const files = await Promise.all(promises);
    const filesData = _.merge({}, ...files);

    return (await this.patch(`/_/admin-api/products/${productId}`, { ...productData, ...filesData })).data;
  };

  updateStore = async (storeId: string, data: Partial<Store>) => {
    return (await this.patch(`/_/admin-api/stores/${storeId}`, data, {})).data;
  };

  updateTeam = async (teamId: string, data: Partial<Team>) => {
    return (await this.patch(`/_/admin-api/teams/${teamId}`, data, {})).data;
  };

  updateTeamLeaderboardOptions = async (teamId: string, data: { enabled: boolean; anonymous: boolean }) => {
    await this.patch(`/_/admin-api/teams/${teamId}/leaderboard`, data);
  };

  protected delete = <T = any>(uri: string, config: AxiosRequestConfig = {}) =>
    this.request<T>({
      url: uri,
      method: 'DELETE',
      ...config,
    });

  protected get = <T = any>(uri: string, config: AxiosRequestConfig = {}) =>
    this.request<T>({
      url: uri,
      method: 'GET',
      ...config,
    });

  protected patch = <T = any>(uri: string, data?: any, config: AxiosRequestConfig = {}) =>
    this.request<T>({
      url: uri,
      method: 'PATCH',
      data,
      ...config,
    });

  protected put = <T = any>(uri: string, data?: any, config: AxiosRequestConfig = {}) =>
    this.request<T>({
      url: uri,
      method: 'PUT',
      data,
      ...config,
    });

  protected post = <T = any>(uri: string, data?: any, config: AxiosRequestConfig = {}) =>
    this.request<T>({
      url: uri,
      method: 'POST',
      data,
      ...config,
    });

  protected request = async <T = any>(config: AxiosRequestConfig) => {
    let token;
    try {
      token = await this.tokenManager.getAccessToken();
      if (!token) throw new Error();
    } catch (err) {
      this.notifyListeners(RepositoryEvent.AuthenticationError);
      throw err;
    }

    // Authorise the request.
    const headers: AxiosRequestConfig['headers'] = config.headers || {};
    if (config.url?.startsWith('/_/')) {
      headers['Authorization'] = `Bearer ${token.access_token}`;
    } else {
      headers['X-Moot-Auth'] = token.access_token;
    }

    try {
      return await this._axios.request<T>({ ...config, headers });
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        this.notifyListeners(RepositoryEvent.AuthenticationError);
      }
      throw err;
    }
  };

  addListener = (type: RepositoryEvent, callback: ListenerCallback) => {
    this.listeners[type] = this.listeners[type] || [];
    this.listeners[type].push(callback);
  };

  protected notifyListeners = (type: RepositoryEvent, ...args: any[]) => {
    const listeners = this.listeners[type] || [];
    listeners.forEach((cb) => {
      cb(...args);
    });
  };

  removeListener = (type: RepositoryEvent, callback: ListenerCallback) => {
    this.listeners[type] = this.listeners[type].filter((c) => c !== callback);
  };
}
