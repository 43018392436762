import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Redirect, useHistory, useParams } from 'react-router';
import { useMembership, usePermissionChecker } from '../../lib/hooks';
import { useRepo } from '../../lib/repository';
import { AccountMember, AccountRole, Permission } from '../../lib/types';
import { Button, DangerButton, PrimaryButton } from '../Buttons';
import { PageHeader } from '../layouts/partials/PageHeaders';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { ConfirmModal } from '../Modals';
import { Placeholder } from '../Placeholders';
import { broadcastSuccessToast } from '../Toasts';
import { HasBravaEnabled } from '../utils/HasTicketsEnabled';
import { AccountMemberRoleForm } from './MemberInvite';

const useMemberUpdateMutation = () => {
  const repo = useRepo();
  const queryClient = useQueryClient();
  return useMutation(
    ({ memberId, role, store_ids, team_ids }: { memberId: string; role: AccountRole; store_ids: string[]; team_ids: string[] }) =>
      repo.updateMemberRole(memberId, { role, team_ids, store_ids }),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(['member', variables.memberId], data);
        queryClient.invalidateQueries('members');
      },
    }
  );
};

export const useMemberDeleteMutation = () => {
  const queryClient = useQueryClient();
  const repo = useRepo();
  return useMutation(({ member }: { member: AccountMember }) => repo.deleteMember(member.id), {
    onSettled: (data, error, { member }) => {
      queryClient.invalidateQueries('members');
      queryClient.invalidateQueries(['member', member.id]);
    },
  });
};

const useMember = (memberId: string) => {
  const repo = useRepo();
  return useQuery(['member', memberId], () => {
    return repo.getMember(memberId);
  });
};

const MemberPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { memberId } = useParams<{ memberId: string }>();
  const { data: member, isLoading } = useMember(memberId);
  const mutation = useMemberUpdateMutation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const membership = useMembership();
  const permChecker = usePermissionChecker();

  const canDelete =
    permChecker.hasAccountPermission(Permission.ManageMember) && member?.role && permChecker.canAssignRole(member?.role);
  const deleteMutation = useMemberDeleteMutation();

  const roles = Object.values([
    AccountRole.Owner,
    AccountRole.Admin,
    AccountRole.Manager,
    AccountRole.StoreClerk,
    AccountRole.TeamCaptain,
  ]).filter((role) => membership?.assignable_roles.includes(role));

  if (!roles.length) {
    return <Redirect to="/" />;
  }

  return (
    <LayoutWithSidebar>
      {isLoading || !member ? (
        t('loadingEllipsis')
      ) : (
        <>
          <PageHeader>{member.user.name}</PageHeader>
          <div className="mb-8  space-y-8">
            <HasBravaEnabled>
              {[AccountRole.Owner, AccountRole.Admin, AccountRole.Manager, AccountRole.TeamCaptain].includes(member.role) ? (
                <div>
                  <div className="w-96">
                    <div className="leading-6 mb-1 text-gray-700 text-sm font-medium">{t('brava')}</div>
                    <div>
                      {member ? (
                        <>
                          {member.brava.given} / {(member.brava.allowance ?? Infinity) === Infinity ? '∞' : member.brava.allowance}
                        </>
                      ) : (
                        <Placeholder className="w-24 h-6" />
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </HasBravaEnabled>
          </div>
          <Formik
            initialValues={{
              role: member.role,
              store_ids: (member?.store_ids || []).filter((storeId) =>
                permChecker.hasStorePermission(storeId, Permission.ManageMember)
              ),
              team_ids: (member?.team_ids || []).filter((teamId) => permChecker.hasTeamPermission(teamId, Permission.ManageMember)),
            }}
            onSubmit={(values, formik) => {
              mutation.mutate(
                {
                  memberId,
                  ...values,
                },
                {
                  onSuccess: () => {
                    formik.resetForm({ values });
                    formik.setSubmitting(false);
                    broadcastSuccessToast(t('adminUpdated'));
                  },
                }
              );
            }}
            validateOnMount
          >
            {(formik) => {
              const canSubmit = formik.dirty && formik.isValid && !formik.isSubmitting;
              return (
                <Form className="flex flex-col flex-grow">
                  <div className="flex-grow space-y-8">
                    <AccountMemberRoleForm
                      onChange={(field, value) => formik.setFieldValue(field, value)}
                      roles={roles}
                      role={formik.values.role}
                      storeIds={formik.values.store_ids}
                      teamIds={formik.values.team_ids}
                    />
                  </div>

                  <div className="w-full border-t border-gray-100 mt-10 pt-4 flex">
                    <div className="flex-grow">
                      {canDelete ? <DangerButton onClick={() => setShowDeleteModal(true)}>{t('delete')}</DangerButton> : null}
                    </div>
                    <div className="flex flex-row-reverse items-end">
                      <PrimaryButton disabled={!canSubmit} submit className="ml-3">
                        {t('save')}
                      </PrimaryButton>
                      <Button onClick={() => formik.resetForm()}>{t('discard')}</Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>

          {showDeleteModal ? (
            <ConfirmModal
              onConfirm={() => {
                deleteMutation.mutate(
                  { member },
                  {
                    onSuccess: () => {
                      broadcastSuccessToast(t('adminDeleted'));
                      history.push(`/members`);
                    },
                  }
                );
                setShowDeleteModal(false);
              }}
              title={t('deleteAdmin')}
              message={t('deleteAdminConfirm')}
              onCancel={() => setShowDeleteModal(false)}
              confirmButtonText={t('delete')}
            />
          ) : null}
        </>
      )}
    </LayoutWithSidebar>
  );
};

export default MemberPage;
