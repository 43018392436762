import { useContext } from 'react';
import { QueryKey, useMutation, useQueryClient } from 'react-query';
import { AuthContext, useUser } from './auth';
import { PlayerUpdatable, useRepo } from './repository';
import { Account, Player, TeamMarket } from './types';
import { invalidateQueryDataAfterBravaAward } from './queries';

export const useAddRecentAccountMutation = () => {
  const queryClient = useQueryClient();
  const user = useUser();
  const { patchUser } = useContext(AuthContext);
  const repo = useRepo();

  const origAccountIds = user.recent_account_ids;
  const origQueryKey = ['accounts', 'recent', origAccountIds];

  return useMutation(
    async (account: Account) => {
      if (origAccountIds && origAccountIds[0] === account.id) {
        return origAccountIds;
      }
      return await repo.reportRecentlyAccessedAccount(account.id);
    },
    {
      onSuccess: (accountIds, account) => {
        const oldAccounts = queryClient.getQueryData<Account[]>(origQueryKey) || [];

        queryClient.setQueryData<Account[] | undefined>(
          ['accounts', 'recent', accountIds],
          (oldAccountsInNewKey: Account[] | undefined = []) => {
            const pool = [account, ...oldAccounts, ...oldAccountsInNewKey];
            const newAccounts = accountIds.map((id) => pool.find((a) => a.id === id)).filter(Boolean) as Account[];
            // If we are missing any, ignore. This can happen if the cache is empty.
            if (newAccounts.length !== accountIds.length) {
              return;
            }
            return newAccounts;
          }
        );

        patchUser({ recent_account_ids: accountIds });
      },
    }
  );
};

export const useItemDownloadFileMutation = () => {
  const repo = useRepo();
  return useMutation(
    async (itemId: string) => {
      return repo.getItemFileDownloadUrl(itemId);
    },
    {
      onSuccess: (url) => {
        window.location.href = url;
      },
    }
  );
};

export const useItemDeleteMutation = () => {
  const repo = useRepo();
  const queryClient = useQueryClient();
  return useMutation(
    (variables: { itemId: string; storeId: string }) => {
      return repo.deleteItem(variables.itemId);
    },
    {
      onSuccess: (data, variables) => {
        queryClient.removeQueries(['item', variables.itemId]);
        queryClient.removeQueries(['item-transactions', variables.itemId]);
        queryClient.invalidateQueries(['store-content', variables.storeId]);
        queryClient.invalidateQueries(['team-market']);
      },
    }
  );
};

export const useItemDuplicateMutation = () => {
  const repo = useRepo();
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: { itemId: string; name: string }) => {
      return repo.duplicateItem(variables.itemId, variables.name);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['store-content', data.store_id]);
        queryClient.invalidateQueries(['team-market']);
      },
    }
  );
};

export const useProductDeleteMutation = () => {
  const repo = useRepo();
  const queryClient = useQueryClient();
  return useMutation(
    (variables: { productId: string; storeId: string }) => {
      return repo.deleteProduct(variables.productId);
    },
    {
      onSuccess: (data, variables) => {
        queryClient.removeQueries(['product', variables.productId]);
        queryClient.removeQueries(['product-items', variables.productId]);
        queryClient.removeQueries(['product-transactions', variables.productId]);
        queryClient.invalidateQueries(['store-content', variables.storeId]);
        queryClient.invalidateQueries(['team-market']);
      },
    }
  );
};

export const useProductDuplicateMutation = () => {
  const repo = useRepo();
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: { productId: string; name: string }) => {
      return repo.duplicateProduct(variables.productId, variables.name);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['store-content', data.store_id]);
        queryClient.invalidateQueries(['team-market']);
      },
    }
  );
};

export const usePlayerBravaMutation = () => {
  const queryClient = useQueryClient();
  const repo = useRepo();
  return useMutation(
    ({
      player,
      amount,
      comment,
      message,
      reason,
    }: {
      player: Player;
      amount: number;
      comment: string;
      message?: string;
      reason: string;
    }) => {
      return repo.awardBrava(player.id, amount, reason, comment, message);
    },
    {
      onSuccess: (data, { player }) => {
        invalidateQueryDataAfterBravaAward(queryClient, player.account_id, player.school_id, player.id);
      },
      onError: () => {
        // TODO Display message.
      },
    }
  );
};

export const usePlayerCoinsMutation = () => {
  const queryClient = useQueryClient();
  const repo = useRepo();
  return useMutation(
    ({ player, coins, message, reason }: { player: Player; coins: number; message?: string; reason?: string }) => {
      return repo.awardCoins(player.id, coins, message, reason);
    },
    {
      onSuccess: (data, { player }) => {
        queryClient.invalidateQueries('users');
        queryClient.invalidateQueries(['svsleaderboard']);
        queryClient.invalidateQueries(['team-leaderboard', player.school_id]);
        queryClient.invalidateQueries(['team-players', player.school_id]);
        queryClient.invalidateQueries(['user', player.id]);
        queryClient.invalidateQueries(['user-transactions', player.id]);
        queryClient.invalidateQueries(['transactions', player.account_id]);
        // To invalidate brava given and allowances.
        queryClient.invalidateQueries(['team', player.school_id]);
        queryClient.invalidateQueries(['account-membership', player.account_id]);
      },
      onError: () => {
        // TODO Display message.
      },
    }
  );
};

export const usePlayerTicketsMutation = () => {
  const queryClient = useQueryClient();
  const repo = useRepo();
  return useMutation(
    ({ player, amount }: { player: Player; amount: number }) => {
      return repo.awardTickets(player.id, amount);
    },
    {
      onSuccess: (data, { player }) => {
        queryClient.invalidateQueries('users');
        queryClient.invalidateQueries(['team-players', player.school_id]);
        queryClient.invalidateQueries(['user', player.id]);
        queryClient.invalidateQueries(['user-transactions', player.id]);
        queryClient.invalidateQueries(['transactions', player.account_id]);
        // To invalidate brava given and allowances.
        queryClient.invalidateQueries(['team', player.school_id]);
        queryClient.invalidateQueries(['account-membership', player.account_id]);
      },
      onError: () => {
        // TODO Display message.
      },
    }
  );
};

export const usePlayerDeleteMutation = (queryKey?: QueryKey) => {
  const queryClient = useQueryClient();
  const repo = useRepo();
  return useMutation(({ player }: { player: Player }) => repo.deletePlayer(player.id), {
    onMutate: async ({ player }) => {
      await queryClient.cancelQueries('users');
      let oldData: any; // ReturnType<typeof useUsers>['data'] | undefined;
      let oldAccountData: any;

      // Optimistic update on the currently viewed list of users.
      if (queryKey) {
        oldData = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, (data: any) => {
          if (!data) return data;
          return {
            ...data,
            total: Math.max(data.total - 1, 1),
            items: data.items.filter((u: any) => u.id !== player.id),
          };
        });

        oldAccountData = queryClient.getQueryData(['account', player.account_id]);
        queryClient.setQueryData(['account', player.account_id], (data: any) => {
          if (!data) return data;
          return {
            ...data,
            cur_users: Math.max(0, (data.cur_users || 0) - 1),
          };
        });
      }

      return { oldData, oldAccountData };
    },
    onSettled: (data, error, { player }) => {
      queryClient.invalidateQueries(['accounts']);
      queryClient.invalidateQueries(['account', player.account_id]);
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['svsleaderboard']);
      queryClient.invalidateQueries(['team-players', player.school_id]);
      queryClient.invalidateQueries(['team-leaderboard', player.school_id]);
      queryClient.invalidateQueries(['user', player.id]);
      queryClient.invalidateQueries(['user-orders', player.id]);
      queryClient.invalidateQueries(['user-transactions', player.id]);
      queryClient.invalidateQueries(['transactions', player.account_id]);
      queryClient.invalidateQueries(['orders', player.account_id]);
    },
    onError: (err, variables, context: any) => {
      if (queryKey) {
        queryClient.setQueryData(queryKey, context.oldData);
      }
      queryClient.setQueryData(['account', variables.player.account_id], context.oldAccountData);
      // TODO Display message.
    },
  });
};

export const usePlayerMutation = () => {
  const queryClient = useQueryClient();
  const repo = useRepo();
  return useMutation(
    ({ player, data }: { player: Player; data: PlayerUpdatable }) => {
      return repo.updatePlayer(player.id, data);
    },
    {
      onSuccess: (data, { player }) => {
        queryClient.invalidateQueries('users');
        queryClient.invalidateQueries(['team-players', player.school_id]);
        queryClient.invalidateQueries(['user', player.id]);
      },
      onError: () => {
        // TODO Display message.
      },
    }
  );
};

export const usePlayerTeamChangeMutation = () => {
  const queryClient = useQueryClient();
  const repo = useRepo();
  return useMutation(
    ({ player, teamId }: { player: Player; teamId: string }) => {
      return repo.setPlayerTeam(player.id, teamId);
    },
    {
      onSuccess: (data, { player, teamId }) => {
        const origTeamId = player.school_id;
        queryClient.invalidateQueries('users');
        queryClient.invalidateQueries(['team', teamId]);
        queryClient.invalidateQueries(['team', origTeamId]);
        queryClient.invalidateQueries(['team-leaderboard', teamId]);
        queryClient.invalidateQueries(['team-leaderboard', origTeamId]);
        queryClient.invalidateQueries(['team-players', teamId]);
        queryClient.invalidateQueries(['team-players', origTeamId]);
        queryClient.invalidateQueries(['user', player.id]);
      },
      onError: () => {
        // TODO Display message.
      },
    }
  );
};

export const useTeamMarketMutation = () => {
  const queryClient = useQueryClient();
  const repo = useRepo();
  return useMutation(
    ({ teamId, categories }: { teamId: string; categories: TeamMarket['categories'] }) => {
      return repo.setTeamMarket(teamId, categories);
    },
    {
      onSuccess: (data, { teamId }) => {
        queryClient.invalidateQueries(['team-market', teamId]);
      },
      onError: () => {
        // TODO Display message.
      },
    }
  );
};
