export type Reason = {
  id: string;
  label: string;
  description: string;
  category: string;
};

export const REASONS: Reason[] = [
  {
    id: 'thankYou',
    label: 'bravaReason.thankYouLabel',
    description: 'bravaReason.thankYouDesc',
    category: 'bravaReasonCategory.general',
  },
  {
    id: 'greatJob',
    label: 'bravaReason.greatJobLabel',
    description: 'bravaReason.greatJobDesc',
    category: 'bravaReasonCategory.general',
  },
  {
    id: 'welcome',
    label: 'bravaReason.welcomeLabel',
    description: 'bravaReason.welcomeDesc',
    category: 'bravaReasonCategory.general',
  },
  {
    id: 'celebrations',
    label: 'bravaReason.celebrationsLabel',
    description: 'bravaReason.celebrationsDesc',
    category: 'bravaReasonCategory.personalMilestones',
  },
  {
    id: 'personalDevelopment',
    label: 'bravaReason.personalDevelopmentLabel',
    description: 'bravaReason.personalDevelopmentDesc',
    category: 'bravaReasonCategory.personalMilestones',
  },
  {
    id: 'communityImpact',
    label: 'bravaReason.communityImpactLabel',
    description: 'bravaReason.communityImpactDesc',
    category: 'bravaReasonCategory.personalMilestones',
  },
  {
    id: 'wellnessAchievements',
    label: 'bravaReason.wellnessAchievementsLabel',
    description: 'bravaReason.wellnessAchievementsDesc',
    category: 'bravaReasonCategory.personalMilestones',
  },
  {
    id: 'workLifeBalanceSuccess',
    label: 'bravaReason.workLifeBalanceSuccessLabel',
    description: 'bravaReason.workLifeBalanceSuccessDesc',
    category: 'bravaReasonCategory.personalMilestones',
  },
  {
    id: 'teamPlayer',
    label: 'bravaReason.teamPlayerLabel',
    description: 'bravaReason.teamPlayerDesc',
    category: 'bravaReasonCategory.behavioralExcellence',
  },
  {
    id: 'mentor',
    label: 'bravaReason.mentorLabel',
    description: 'bravaReason.mentorDesc',
    category: 'bravaReasonCategory.behavioralExcellence',
  },
  {
    id: 'positiveAttitude',
    label: 'bravaReason.positiveAttitudeLabel',
    description: 'bravaReason.positiveAttitudeDesc',
    category: 'bravaReasonCategory.behavioralExcellence',
  },
  {
    id: 'innovator',
    label: 'bravaReason.innovatorLabel',
    description: 'bravaReason.innovatorDesc',
    category: 'bravaReasonCategory.behavioralExcellence',
  },
  {
    id: 'learner',
    label: 'bravaReason.learnerLabel',
    description: 'bravaReason.learnerDesc',
    category: 'bravaReasonCategory.behavioralExcellence',
  },
  {
    id: 'safetyLeader',
    label: 'bravaReason.safetyLeaderLabel',
    description: 'bravaReason.safetyLeaderDesc',
    category: 'bravaReasonCategory.behavioralExcellence',
  },
  {
    id: 'collaboration',
    label: 'bravaReason.collaborationLabel',
    description: 'bravaReason.collaborationDesc',
    category: 'bravaReasonCategory.teamBasedRecognition',
  },
  {
    id: 'projectSuccess',
    label: 'bravaReason.projectSuccessLabel',
    description: 'bravaReason.projectSuccessDesc',
    category: 'bravaReasonCategory.teamBasedRecognition',
  },
  {
    id: 'efficiencyBoost',
    label: 'bravaReason.efficiencyBoostLabel',
    description: 'bravaReason.efficiencyBoostDesc',
    category: 'bravaReasonCategory.teamBasedRecognition',
  },
  {
    id: 'topPerformer',
    label: 'bravaReason.topPerformerLabel',
    description: 'bravaReason.topPerformerDesc',
    category: 'bravaReasonCategory.perfBasedRecognition',
  },
  {
    id: 'crisisLeader',
    label: 'bravaReason.crisisLeaderLabel',
    description: 'bravaReason.crisisLeaderDesc',
    category: 'bravaReasonCategory.perfBasedRecognition',
  },
  {
    id: 'customerHero',
    label: 'bravaReason.customerHeroLabel',
    description: 'bravaReason.customerHeroDesc',
    category: 'bravaReasonCategory.perfBasedRecognition',
  },
  {
    id: 'costSaver',
    label: 'bravaReason.costSaverLabel',
    description: 'bravaReason.costSaverDesc',
    category: 'bravaReasonCategory.perfBasedRecognition',
  },
  // {
  //   id: 'workAnniversary',
  //   label: 'bravaReason.workAnniversaryLabel',
  //   description: 'bravaReason.workAnniversaryDesc',
  //   category: 'bravaReasonCategory.calendarEvents',
  // },
  // {
  //   id: 'birthdays',
  //   label: 'bravaReason.birthdaysLabel',
  //   description: 'bravaReason.birthdaysDesc',
  //   category: 'bravaReasonCategory.calendarEvents',
  // },
  // {
  //   id: 'newTeammate',
  //   label: 'bravaReason.newTeammateLabel',
  //   description: 'bravaReason.newTeammateDesc',
  //   category: 'bravaReasonCategory.calendarEvents',
  // },
];

export const getCategoryForReason = (reasonId: string): string => {
  return REASONS.find((r) => r.id === reasonId)?.category ?? 'general';
};

export const getColorForReason = (reasonId: string): [string, string | undefined] => {
  const cat = getCategoryForReason(reasonId);
  let bg = '#A7C7E7';
  let fg: undefined | string = undefined;

  switch (cat) {
    case 'personalMilestones':
      bg = '#A9D18E';
      break;
    case 'behavioralExcellence':
      bg = '#F4C842';
      break;
    case 'teamBasedRecognition':
      bg = '#76C7C0';
      break;
    case 'perfBasedRecognition':
      bg = '#F4A259';
      break;
    case 'calendarEvents':
      if (reasonId === 'workAnniversary') {
        bg = '#A377C6';
        fg = '#fff';
      } else if (reasonId === 'birthdays') {
        bg = '#FF9AA2';
      } else if (reasonId === 'newTeammate') {
        bg = '#FFC58B';
      } else {
        bg = '#A377C6';
      }
      break;
    case 'general':
    default:
      bg = '#A7C7E7';
      break;
  }

  return [bg, fg];
};
