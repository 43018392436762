import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { format, formatISO, fromUnixTime } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import { getBravaCurrency, useAccount } from '../../../lib/account';
import { useUser } from '../../../lib/auth';
import { getColorForReason, Reason, REASONS } from '../../../lib/brava';
import { formatTimeAgoShortDuration } from '../../../lib/date';
import { useMembership, usePermissionChecker } from '../../../lib/hooks';
import { usePlayerBravaMutation } from '../../../lib/mutations';
import { invalidateQueryDataAfterBravaAward } from '../../../lib/queries';
import { BravaFeedReturn, useRepo } from '../../../lib/repository';
import { BravaFeedEntry, Permission, Player } from '../../../lib/types';
import { classNames, getMemberBravaAllowance, getTeamBravaAllowance } from '../../../lib/utils';
import { Button, PrimaryButton } from '../../Buttons';
import { TextInput } from '../../inputs/Input';
import { TextareaInput } from '../../inputs/Textarea';
import TeamPageLayout, { useTeamPage } from '../../layouts/TeamPage';
import { HasTeamPermission } from '../../Permissions';
import { Placeholder } from '../../Placeholders';
import { broadcastSuccessToast } from '../../Toasts';
import { BravaCurrencyIcon, CurrencyIcon } from '../../widgets/Coins';
import Dropdown from '../../widgets/Dropdown';
import { HiddenTag, PrivateTag } from '../../widgets/Tag';
import { useParams } from 'react-router';

type PlayersMap = Map<string, BravaFeedReturn['players'][number]>;
type DashboardUsersMap = Map<string, BravaFeedReturn['users'][number]>;

export const TeamBravaPage = () => {
  const membership = useMembership();
  const { teamId } = useParams<{ teamId: string }>();

  return (
    <TeamPageLayout activeNavPill="brava">
      <div className="flex gap-4">
        <div className="grow-0 shrink-0 w-64 flex gap-4 flex-col">
          <AllowanceStats />
        </div>
        <div className="grow">
          <HasTeamPermission teamId={teamId} perm={Permission.CreateBravaFeed}>
            <div className="mb-6">
              <CommentWriter />
            </div>
          </HasTeamPermission>
          <div>
            <BravaFeed />
          </div>
        </div>
      </div>
    </TeamPageLayout>
  );
};

const useBravaFeed = (teamId: string) => {
  const repo = useRepo();
  return useInfiniteQuery(
    ['brava-feed', teamId],
    ({ pageParam }) => {
      return repo.getBravaFeed(teamId, pageParam);
    },
    {
      getNextPageParam: (lastPage) => lastPage.continuation,
    }
  );
};

const useBravaFeedEntryHideMutation = (teamId: string) => {
  const repo = useRepo();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ entryId, hidden }: { entryId: string; hidden: boolean }) => {
      return repo.setBravaFeedEntryVisibility(teamId, entryId, hidden);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['brava-feed', teamId]);
      },
    }
  );
};

const useBravaFeedCommentHideMutation = (teamId: string) => {
  const repo = useRepo();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ entryId, commentId, hidden }: { entryId: string; commentId: string; hidden: boolean }) => {
      return repo.setBravaFeedEntryCommentVisibility(teamId, entryId, commentId, hidden);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['brava-feed', teamId]);
      },
    }
  );
};

const BravaFeed = () => {
  const team = useTeamPage().team;
  const { t } = useTranslation();
  const feed = useBravaFeed(team.id);
  const entryVisMutation = useBravaFeedEntryHideMutation(team.id);
  const commentVisMutation = useBravaFeedCommentHideMutation(team.id);

  const handleEntryVisibilityChange = useCallback(
    (entryId: string, hidden: boolean) => {
      entryVisMutation.mutate({ entryId, hidden });
    },
    [entryVisMutation]
  );

  const handleCommentVisibilityChange = useCallback(
    (entryId: string, commentId: string, hidden: boolean) => {
      commentVisMutation.mutate({ entryId, commentId, hidden });
    },
    [commentVisMutation]
  );

  const feedItems = useMemo(() => {
    return feed.data?.pages?.flatMap((p) => p.items) ?? [];
  }, [feed.data]);
  const usersMap: DashboardUsersMap = useMemo(() => {
    return new Map(feed.data?.pages?.flatMap((p) => p.users.map((user) => [user.id, user]) ?? []));
  }, [feed.data]);
  const playersMap: PlayersMap = useMemo(() => {
    return new Map(feed.data?.pages?.flatMap((p) => p.players.map((user) => [user.id, user]) ?? []));
  }, [feed.data]);

  if (feed.isLoading) {
    return null;
  }

  return (
    <div className="space-y-2">
      {feedItems.map((item) => {
        return (
          <BravaFeedEntryItem
            key={item.id}
            entry={item}
            players={playersMap}
            users={usersMap}
            onVisibilityChange={handleEntryVisibilityChange}
            onCommentVisibilityChange={handleCommentVisibilityChange}
          />
        );
      })}
      {feed.hasNextPage ? (
        <div className="mt-4">
          <Button disabled={!feed.hasNextPage || feed.isFetching} onClick={(e) => feed.fetchNextPage()}>
            {t('loadMore')}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

const BravaAmount = ({
  amount,
  currency,
  showIcon = true,
}: {
  amount: number;
  currency: 'coins' | 'tickets';
  showIcon?: boolean;
}) => {
  return (
    <div className="gap-1 items-center inline-flex">
      {`+${amount}`}
      {showIcon ? <CurrencyIcon currency={currency ?? 'coins'} /> : null}
    </div>
  );
};

const getPersonName = (
  type: 'player' | 'user' | 'system',
  id: string | undefined,
  players: PlayersMap,
  users: DashboardUsersMap
) => {
  if (type === 'player') {
    const player = players.get(id!);
    return player ? `${player.firstname} ${player.lastname}` : null;
  } else if (type === 'user') {
    const user = users.get(id!);
    return user ? user.name : null;
  }
  return null;
};

const BravaFeedEntryItem = (props: {
  entry: BravaFeedEntry;
  players: PlayersMap;
  users: DashboardUsersMap;
  onVisibilityChange: (entryId: string, hidden: boolean) => void;
  onCommentVisibilityChange: (entryId: string, commentId: string, hidden: boolean) => void;
}) => {
  const [openComment, setOpenComments] = useState(false);
  const { entry, players, users, onVisibilityChange, onCommentVisibilityChange } = props;
  const { t } = useTranslation();
  const account = useAccount();
  const permChecker = usePermissionChecker();

  const authorName = getPersonName(entry.author_type, entry.author_id, players, users) ?? t('deletedUser');
  const recipientName = useMemo(() => {
    const player = players.get(entry.brava_rcpt_id!);
    return player ? `${player.firstname} ${player.lastname}` : t('deletedUser');
  }, [players, entry.brava_rcpt_id, t]);
  const handleOpenComment = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpenComments(true);
  }, []);
  const actions = useMemo(() => {
    return [
      {
        label: t('hide'),
        can: !Boolean(entry.hidden) && permChecker.hasTeamPermission(entry.team_id, Permission.ModerateBravaFeed),
        onClick: () => onVisibilityChange(entry.id, true),
      },
      {
        label: t('unhide'),
        can: Boolean(entry.hidden) && permChecker.hasTeamPermission(entry.team_id, Permission.ModerateBravaFeed),
        onClick: () => onVisibilityChange(entry.id, false),
      },
    ].filter((action) => action.can === undefined || action.can);
  }, [entry, permChecker, t, onVisibilityChange]);

  const isPrivate =
    (entry.brava_reason === 'birthdays' && Boolean(players.get(entry.brava_rcpt_id!)?.birthdate_private)) ||
    (entry.brava_reason === 'workAnniversary' && Boolean(players.get(entry.brava_rcpt_id!)?.start_date_private));

  const createdOnDate = fromUnixTime(entry.created_on);
  const hasCurrentCurrency = !entry.brava_currency || entry.brava_currency === getBravaCurrency(account);

  return (
    <div>
      <div className="flex text-lg bg-gray-100 rounded-tr rounded-tl items-center">
        <div className="grow px-3 py-1 font-semibold flex items-center gap-2">
          <div className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-white text-grey-90">👏</div>
          <div>{recipientName}</div>
          {entry.hidden ? (
            <div>
              <HiddenTag>{t('hidden')}</HiddenTag>
            </div>
          ) : null}
          {isPrivate ? (
            <div>
              <PrivateTag>{t('private')}</PrivateTag>
            </div>
          ) : null}
        </div>
        <div className="flex grow-0 items-center gap-3">
          <div className="grow-0 shrink-0 whitespace-nowrap">
            {entry.brava_total ? <BravaAmount amount={entry.brava_total} currency={entry.brava_currency ?? 'coins'} /> : null}
          </div>
          <div className="grow-0 shrink-0 whitespace-nowrap">
            <FeedEntryReasonTag reason={entry.brava_reason} />
          </div>
        </div>
        {actions.length ? (
          <div className="grow-0 shrink-0 flex items-center px-2">
            <Dropdown right label={t('actions')} icon={<EllipsisVerticalIcon className="h-5 w-5" />} minimal options={actions} />
          </div>
        ) : null}
      </div>
      <div className="pt-4 p-3 border border-gray-100 border-t-0 rounded-br rounded-bl">
        <div className="flex flex-row gap-3">
          {entry.author_id ? (
            <div className="shrink-0">
              <div
                className="inline-block bg-gray-100 rounded px-2 py-1 font-bold"
                title={authorName ?? ''}
                style={{ maxWidth: '30ch' }}
              >
                {authorName}
              </div>
            </div>
          ) : null}
          <div className={classNames('grow', !entry.brava_amount ? 'pt-1' : '')}>
            {entry.brava_amount ? (
              <div className="inline-block bg-gray-100 rounded px-2 py-1 font-bold mr-3">
                <BravaAmount amount={entry.brava_amount} currency={entry.brava_currency!} showIcon={false} />
              </div>
            ) : null}
            {entry.message}
          </div>
          <div className="shrink-0 grow-0 text-gray-700 pt-1">
            <FeedEntryTime date={createdOnDate} />
          </div>
        </div>
        <HasTeamPermission teamId={entry.team_id} perm={Permission.CreateBravaFeed}>
          <div className="my-4 flex gap-4 last:mb-0 h-10">
            <div className="grow flex items-stretch">
              {!openComment ? (
                <Button onClick={handleOpenComment} className="h-full">
                  {t('addBrava')}
                </Button>
              ) : (
                <div className="flex grow gap-1">
                  <div className="grow">
                    <EntryCommentWriter entry={entry} withBrava={hasCurrentCurrency} />
                  </div>
                  <button className="flex items-center justify-center" type="button" onClick={() => setOpenComments(false)}>
                    <span className="sr-only">{t('close')}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                      <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                    </svg>
                  </button>
                </div>
              )}
            </div>
            <div className="flex sm:justify-center items-stretch">
              <EntryLikeButton entry={entry} />
            </div>
          </div>
        </HasTeamPermission>
        {entry.comments - (entry.comments_hidden ?? 0) > 0 ? (
          <div className="mt-4 space-y-3">
            {(entry.comments_top ?? []).map((comment) => {
              return (
                <BravaFeedEntryComment
                  key={comment.id}
                  entry={entry}
                  comment={comment}
                  users={users}
                  players={players}
                  onVisibilityChange={onCommentVisibilityChange}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const FeedEntryTime = ({ date }: { date: Date }) => {
  const { t } = useTranslation();
  return (
    <time title={format(date, 'PPPP pp')} dateTime={formatISO(date)}>
      {formatTimeAgoShortDuration(t, date)}
    </time>
  );
};

const FeedEntryReasonTag = ({ reason }: { reason: string }) => {
  const { t } = useTranslation();
  const [bg, fg] = getColorForReason(reason);
  return (
    <div className="text-sm px-2 py-1 rounded" style={{ color: fg, backgroundColor: bg }}>
      {t(`bravaFeed.reason.${reason}`)}
    </div>
  );
};

const EntryLikeButton = ({ entry }: { entry: BravaFeedEntry }) => {
  const { id } = useUser();
  const repo = useRepo();
  const queryClient = useQueryClient();
  const isLiked = entry.likedby_users.includes(id);
  const team = useTeamPage().team;

  const mutation = useMutation(
    async ({ entryId, like }: { entryId: string; like: boolean }) => {
      await repo.likeBravaFeedEntry(team.id, entryId, like);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['brava-feed', entry.team_id]);
      },
    }
  );

  const handleClick = () => {
    mutation.mutate({ entryId: entry.id, like: !isLiked });
  };

  return (
    <Button onClick={handleClick} disabled={mutation.isLoading} className={classNames(isLiked ? 'text-blue-600' : null)}>
      <div className="flex items-center">
        <div className="grow text-center min-w-8">{entry.likes}</div>
        <div className="leading-none">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5">
            <path d="M7.493 18.5c-.425 0-.82-.236-.975-.632A7.48 7.48 0 0 1 6 15.125c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75A.75.75 0 0 1 15 2a2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23h-.777ZM2.331 10.727a11.969 11.969 0 0 0-.831 4.398 12 12 0 0 0 .52 3.507C2.28 19.482 3.105 20 3.994 20H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 0 1-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227Z" />
          </svg>
        </div>
      </div>
    </Button>
  );
};

const BravaFeedEntryComment = ({
  entry,
  comment,
  users,
  players,
  onVisibilityChange,
}: {
  entry: BravaFeedEntry;
  comment: NonNullable<BravaFeedEntry['comments_top']>[number];
  users: DashboardUsersMap;
  players: PlayersMap;
  onVisibilityChange: (entryId: string, commentId: string, hidden: boolean) => void;
}) => {
  const { t } = useTranslation();
  const createdOnDate = fromUnixTime(comment.created_on);
  const permChecker = usePermissionChecker();
  const actions = useMemo(() => {
    return [
      {
        label: t('hide'),
        can: !Boolean(comment.hidden) && permChecker.hasTeamPermission(entry.team_id, Permission.ModerateBravaFeed),
        onClick: () => onVisibilityChange(entry.id, comment.id, true),
      },
      {
        label: t('unhide'),
        can: Boolean(comment.hidden) && permChecker.hasTeamPermission(entry.team_id, Permission.ModerateBravaFeed),
        onClick: () => onVisibilityChange(entry.id, comment.id, false),
      },
    ].filter((action) => action.can === undefined || action.can);
  }, [entry, comment, permChecker, t, onVisibilityChange]);
  const name = getPersonName(comment.author_type, comment.author_id, players, users) ?? t('deletedUser');

  return (
    <div className="text-sm flex gap-2">
      <div className="grow">
        <div className="inline-block bg-gray-100 rounded px-1.5 py-0.5 mr-2">{name}</div>
        {comment.brava_amount ? (
          <div className="inline-block bg-gray-100 rounded px-1.5 py-0.5 mr-2">
            <BravaAmount amount={comment.brava_amount} currency={entry.brava_currency!} showIcon={false} />
          </div>
        ) : null}
        {comment.hidden ? (
          <div className="inline-block mr-2">
            <HiddenTag>{t('hidden')}</HiddenTag>
          </div>
        ) : null}
        {comment.message}
      </div>
      <div className="pt-1 shrink-0 text-gray-700">
        <FeedEntryTime date={createdOnDate} />
      </div>
      {actions.length ? (
        <div className="pt-1 grow-0 shrink-0 leading-none">
          <Dropdown right label={t('actions')} icon={<EllipsisVerticalIcon className="h-5 w-5" />} minimal options={actions} />
        </div>
      ) : null}
    </div>
  );
};

const RecipientSelect = (props: { id: string; value?: Player | null; onChange: (player: Player | null) => void }) => {
  const repo = useRepo();
  const team = useTeamPage().team;
  const { t } = useTranslation();
  const teamPlayerSearchLoader = useCallback(
    async (searchTerm: string) => {
      return (await repo.getTeamPlayers(team.id, { term: searchTerm }, 'name')).items;
    },
    [repo, team.id]
  );

  return (
    <AsyncSelect<Player>
      className="react-select"
      classNamePrefix="react-select"
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
      placeholder={<span className="text-sm">{t('selectAPlayer')}</span>}
      components={{}}
      inputId={props.id}
      isSearchable={true}
      onChange={props.onChange}
      noOptionsMessage={({ inputValue }) => {
        if (!inputValue) {
          return <>{t('startTypingToSearch')}</>;
        }
        return <>{t('noResultsEllipsis')}</>;
      }}
      loadOptions={teamPlayerSearchLoader}
      value={props.value}
    />
  );
};

const ReasonSelect = (props: { id: string; value?: Reason | null; onChange: (reason: Reason | null) => void }) => {
  const repo = useRepo();
  const team = useTeamPage().team;
  const { t } = useTranslation();

  const options = useMemo(() => {
    const categories = REASONS.reduce((acc, reason) => {
      if (!acc.includes(reason.category)) {
        acc.push(reason.category);
      }
      return acc;
    }, [] as string[]);

    return categories.map((category) => {
      return {
        label: category,
        options: REASONS.filter((reason) => reason.category === category),
      };
    });
  }, []);

  return (
    <ReactSelect<Reason>
      className="react-select"
      classNamePrefix="react-select"
      formatGroupLabel={(group) => t(group.label ?? '')}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => t(option.label)}
      placeholder={<span className="text-sm">{t('selectAReason')}</span>}
      components={{}}
      inputId={props.id}
      isSearchable={true}
      options={options}
      onChange={props.onChange}
      value={props.value}
    />
  );
};

const CommentWriter = () => {
  const [player, setPlayer] = useState<Player | null>(null);
  const [reason, setReason] = useState<Reason | null>(null);
  const [comment, setComment] = useState<string>();
  const [amount, setAmount] = useState<number | undefined>();
  const mutation = usePlayerBravaMutation();
  const membership = useMembership();
  const team = useTeamPage().team;
  const permChecker = usePermissionChecker();

  const { t } = useTranslation();
  const handleAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = parseInt(e.target.value, 10);
      setAmount(isNaN(v) ? undefined : v);
    },
    [setAmount]
  );
  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!player || !comment || !reason) {
        return;
      }
      mutation.mutate(
        { player, amount: amount ?? 0, comment, reason: reason.id },
        {
          onSuccess: () => {
            setPlayer(null);
            setAmount(undefined);
            setComment('');
            setReason(null);
            broadcastSuccessToast(t('bravaSent'));
          },
        }
      );
    },
    [player, amount, comment, reason, mutation, t]
  );

  const allowance = useMemo(() => {
    if (!membership) return Infinity;
    return getMemberBravaAllowance(membership).remaining;
  }, [membership]);

  const teamAllowance = useMemo(() => {
    if (!team) return Infinity;
    return getTeamBravaAllowance(team).remaining;
  }, [team]);

  const maxAmount = useMemo(() => {
    return Math.min(allowance, !permChecker.hasTeamPermission(team.id, Permission.IgnoreTeamBravaLimit) ? teamAllowance : Infinity);
  }, [allowance, teamAllowance, team, permChecker]);

  const canSubmit =
    Boolean(player) &&
    Boolean(reason) &&
    Boolean(comment) &&
    (!amount || (amount > 0 && amount <= maxAmount)) &&
    !mutation.isLoading;

  return (
    <form onSubmit={handleSubmit} className="">
      <div className="grid grid-cols-3 gap-1 mb-1">
        <div>
          <label htmlFor="comment-writer-recipient" className="sr-only block mb-2">
            {t('recipient')}
          </label>
          <RecipientSelect id="comment-writer-recipient" value={player} onChange={setPlayer} />
        </div>
        <div>
          <label htmlFor="comment-writer-reason" className="sr-only block mb-2">
            {t('reason')}
          </label>
          <ReasonSelect id="comment-writer-reason" value={reason} onChange={setReason} />
        </div>
        <div className="">
          <HasTeamPermission teamId={team.id} perm={Permission.AwardBrava}>
            <label className="sr-only block mb-2" htmlFor="comment-writer-amount">
              {t('amount')}
            </label>
            <div className="items-center flex gap-2">
              <TextInput
                type="number"
                id="comment-writer-amount"
                placeholder="100"
                onChange={handleAmountChange}
                value={(amount ?? '')?.toString()}
              />
              <BravaCurrencyIcon />
            </div>
          </HasTeamPermission>
        </div>
      </div>
      <div>
        <TextareaInput
          rows={3}
          placeholder={t('leaveACommentEllipsis')}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          maxLength={280}
        />
      </div>
      <div className="mt-1">
        <PrimaryButton type="submit" disabled={!canSubmit}>
          {t('bravaExcl')}
        </PrimaryButton>
      </div>
    </form>
  );
};

const AllowanceStats = () => {
  const { t } = useTranslation();
  const membership = useMembership();
  const team = useTeamPage().team;

  const allowance = useMemo(() => {
    if (!membership) return null;
    return getMemberBravaAllowance(membership);
  }, [membership]);

  const teamAllowance = useMemo(() => {
    if (!team) return null;
    return getTeamBravaAllowance(team);
  }, [team]);

  return (
    <div className="flex flex-col gap-4">
      <div className="bg-gray-100 rounded p-4 text-center flex flex-col items-center gap-2">
        <div>{t('teamRemainingAllowance')}</div>
        <div className="flex gap-2 items-center leading-none">
          {teamAllowance === null ? <Placeholder className="h-4 w-8" /> : null}
          {teamAllowance !== null && teamAllowance.remaining === Infinity ? '∞' : teamAllowance?.remaining} <BravaCurrencyIcon />
        </div>
      </div>
      <div className="bg-gray-100 rounded p-4 text-center flex flex-col items-center gap-2">
        <div>{t('leftToGive')}</div>
        <div className="flex gap-2 items-center leading-none">
          {allowance === null ? <Placeholder className="h-4 w-8" /> : null}
          {allowance !== null && allowance.remaining === Infinity ? '∞' : allowance?.remaining} <BravaCurrencyIcon />
        </div>
      </div>
      <div className="bg-gray-100 rounded p-4 text-center flex flex-col items-center gap-2">
        <div>{t('givenThisMonth')}</div>
        <div className="flex gap-2 items-center leading-none">
          <span>
            {allowance === null ? <Placeholder className="h-4 w-8" /> : null}
            {allowance !== null && allowance.given === Infinity ? '∞' : allowance?.given}
          </span>
          <BravaCurrencyIcon />
        </div>
      </div>
    </div>
  );
};

const EntryCommentWriter = ({ entry, withBrava }: { entry: BravaFeedEntry; withBrava: boolean }) => {
  const account = useAccount();
  const [comment, setComment] = useState<string>('');
  const [amount, setAmount] = useState<number | undefined>();
  const membership = useMembership();
  const team = useTeamPage().team;
  const permChecker = usePermissionChecker();
  const queryClient = useQueryClient();
  const repo = useRepo();

  const mutation = useMutation(
    async ({ entry, comment, amount }: { entry: BravaFeedEntry; comment: string; amount: number | undefined }) => {
      if (!comment.trim().length) {
        return;
      }
      return repo.createBravaFeedComment(entry.team_id, entry.id, comment, amount);
    },
    {
      onSuccess(data, { entry, amount }, context) {
        queryClient.invalidateQueries(['brava-feed', entry.team_id]);
        if (amount) {
          invalidateQueryDataAfterBravaAward(queryClient, entry.account_id, entry.team_id, entry.brava_rcpt_id);
        }
      },
    }
  );

  const { t } = useTranslation();
  const handleAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = parseInt(e.target.value, 10);
      setAmount(isNaN(v) ? undefined : v);
    },
    [setAmount]
  );
  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!comment) {
        return;
      }
      mutation.mutate(
        { amount, comment, entry },
        {
          onSuccess: () => {
            setAmount(undefined);
            setComment('');
          },
        }
      );
    },
    [entry, amount, comment, mutation]
  );

  const allowance = useMemo(() => {
    if (!membership) return Infinity;
    return getMemberBravaAllowance(membership).remaining;
  }, [membership]);

  const teamAllowance = useMemo(() => {
    if (!team) return Infinity;
    return getTeamBravaAllowance(team).remaining;
  }, [team]);

  const maxAmount = useMemo(() => {
    return Math.min(allowance, !permChecker.hasTeamPermission(team.id, Permission.IgnoreTeamBravaLimit) ? teamAllowance : Infinity);
  }, [allowance, teamAllowance, team, permChecker]);

  const canSubmit = Boolean(comment) && (!amount || (amount > 0 && amount <= maxAmount)) && !mutation.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-row gap-1 text-sm">
        {withBrava ? (
          <div className="grow-0 whitespace-nowrap">
            <div className="items-center inline-flex gap-1">
              <div className="w-24">
                <TextInput
                  type="number"
                  min={0}
                  id="amount"
                  placeholder="0"
                  onChange={handleAmountChange}
                  value={(amount ?? '')?.toString()}
                  hasError={amount !== undefined && (amount < 0 || amount > maxAmount)}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className="grow">
          <TextInput
            placeholder={t('leaveACommentEllipsis')}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            maxLength={280}
          />
        </div>
        <div className="">
          <PrimaryButton type="submit" disabled={!canSubmit} className="h-[100%]">
            {t('bravaExcl')}
          </PrimaryButton>
        </div>
      </div>
    </form>
  );
};
