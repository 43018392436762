import { LinkProps } from 'react-router-dom';
import { Location } from 'history';
import queryString from 'query-string';
import {
  AccountMembership,
  AccountPaymentType,
  AccountRole,
  AccountState,
  BravaAllowance,
  ItemDrawMethod,
  ItemRaffleStatus,
  ItemType,
  OrderState,
  RedemptionMethod,
  Team,
  TransactionType,
} from './types';
import { fromUnixTime, getUnixTime, isSameMonth } from 'date-fns';

export function classNames(...classes: (string | undefined | null)[]) {
  return classes.filter(Boolean).join(' ');
}

export function getLogoutUrl(locationOrTo: Location<unknown> | string): LinkProps['to'] {
  const to = typeof locationOrTo === 'string' ? locationOrTo : locationOrTo.pathname + locationOrTo.search;
  return { pathname: '/logout', search: queryString.stringify({ to }) };
}

export function getSwitchUrl(locationOrTo: Location<unknown> | string): string {
  const to = typeof locationOrTo === 'string' ? locationOrTo : locationOrTo.pathname + locationOrTo.search;
  const search = queryString.stringify({ to });
  return '/switch' + (search ? '?' + search : '');
}

export function getAccountPaymentTypeStringKey(type: AccountPaymentType) {
  if (type === AccountPaymentType.Annual) {
    return 'dashboard:paymentTypeAnnual';
  } else if (type === AccountPaymentType.Trial) {
    return 'dashboard:paymentTypeTrial';
  } else if (type === AccountPaymentType.Monthly) {
    return 'dashboard:paymentTypeMonthly';
  } else if (type === AccountPaymentType.Subscription) {
    return 'dashboard:paymentTypeSubscription';
  } else if (type === AccountPaymentType.Subv2) {
    return 'dashboard:paymentTypeSubv2';
  }
  return type;
}

export function getAccountRoleStringKey(role: AccountRole) {
  if (role === AccountRole.Owner) {
    return 'dashboard:accountRoleOwner';
  } else if (role === AccountRole.Admin) {
    return 'dashboard:accountRoleAdmin';
  } else if (role === AccountRole.Manager) {
    return 'dashboard:accountRoleManager';
  } else if (role === AccountRole.TeamCaptain) {
    return 'dashboard:accountRoleTeamCaptain';
  } else if (role === AccountRole.StoreClerk) {
    return 'dashboard:accountRoleStoreClerk';
  }
  return 'common:unknown';
}

export function getAccountRoleDescriptionStringKey(role: AccountRole) {
  if (role === AccountRole.Owner) {
    return 'dashboard:accountRoleOwnerDesc';
  } else if (role === AccountRole.Admin) {
    return 'dashboard:accountRoleAdminDesc';
  } else if (role === AccountRole.Manager) {
    return 'dashboard:accountRoleManagerDesc';
  } else if (role === AccountRole.TeamCaptain) {
    return 'dashboard:accountRoleTeamCaptainDesc';
  } else if (role === AccountRole.StoreClerk) {
    return 'dashboard:accountRoleStoreClerkDesc';
  }
  return 'common:unknown';
}

export function getAccountStateStringKey(state: AccountState) {
  if (state === AccountState.Active) {
    return 'dashboard:stateActive';
  } else if (state === AccountState.Irregular) {
    return 'dashboard:stateIrregular';
  } else if (state === AccountState.Suspended) {
    return 'dashboard:stateSuspended';
  }
  return 'dashboard:stateUnknown';
}

export function getItemDrawMethodStringKey(method: ItemDrawMethod) {
  if (method === ItemDrawMethod.Manual) {
    return 'dashboard:drawManual';
  } else if (method === ItemDrawMethod.Auto) {
    return 'dashboard:drawAuto';
  }
  return method;
}

export function getItemTypeStringKey(type: ItemType) {
  if (type === ItemType.Purchase) {
    return 'common:item.type.purchase';
  } else if (type === ItemType.Raffle) {
    return 'common:item.type.raffle';
  } else if (type === ItemType.Sweepstakes) {
    return 'common:item.type.sweepstakes';
  } else if (type === ItemType.Auction) {
    return 'common:item.type.auction';
  } else if (type === ItemType.Contribution) {
    return 'common:item.type.contribution';
  }
  return type;
}

export function getRaffleStatusStringKey(status: ItemRaffleStatus) {
  if (status === ItemRaffleStatus.Open) {
    return 'dashboard:raffleStatuses.open';
  } else if (status === ItemRaffleStatus.Closed) {
    return 'dashboard:raffleStatuses.closed';
  }
  return status;
}

export function getRedemptionMethodStringKey(method: RedemptionMethod) {
  if (method === RedemptionMethod.Self) {
    return 'dashboard:redemptionType.self';
  } else if (method === RedemptionMethod.Request) {
    return 'dashboard:redemptionType.request';
  } else if (method === RedemptionMethod.Download) {
    return 'dashboard:redemptionType.download';
  } else if (method === RedemptionMethod.Shipping) {
    return 'dashboard:redemptionType.shipping';
  } else if (method === RedemptionMethod.Voucher) {
    return 'dashboard:redemptionType.voucher';
  }
  return method;
}

export function getOrderStateStringKey(method: OrderState) {
  if (method === OrderState.Idle) {
    return 'dashboard:orderState.idle';
  } else if (method === OrderState.Pending) {
    return 'dashboard:orderState.pending';
  } else if (method === OrderState.Complete) {
    return 'dashboard:orderState.complete';
  }
  return method;
}

/**
 * Computes a member's Brava allowance.
 */
export function getMemberBravaAllowance(membership: AccountMembership): BravaAllowance {
  // Converts null values to Infinity.
  return {
    given: membership.brava.given ?? Infinity,
    remaining: membership.brava.remaining ?? Infinity,
    allowance: membership.brava.allowance ?? Infinity,
  };
}

/**
 * Computes a team's Brava allowance.
 */
export function getTeamBravaAllowance(team: Team) {
  const bravaPeriod = fromUnixTime(team.brava_period ?? getUnixTime(new Date()));
  const isPeriodCurrent = isSameMonth(bravaPeriod, new Date());

  const allowance = team.brava_monthly_allowance ?? Infinity;
  let given = 0;
  let remaining = allowance;

  if (isPeriodCurrent) {
    given = team.brava_given_in_period ?? 0;
    remaining = team.brava_remaining_in_period ?? allowance;
  }

  return {
    given,
    remaining,
    allowance,
  };
}

export function getTransactionTypeStringKey(type: TransactionType) {
  return `transactionType.${type.toLowerCase()}`;
}

export function getAdminRoleStringKey(role: string) {
  let key = 'admin';
  if (role === 'account_admin') {
    key = 'accountAdmin';
  } else if (role === 'school_admin') {
    key = 'teamAdmin';
  }
  return `adminRole.${key}`;
}
