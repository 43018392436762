import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import * as yup from 'yup';
import { useRepo } from '../../lib/repository';
import { Button, PrimaryButton } from '../Buttons';
import Input from '../inputs/Input';
import TeamPageLayout, { useTeamPage } from '../layouts/TeamPage';
import { broadcastSuccessToast } from '../Toasts';
import { HasBravaEnabled, HasTicketsEnabled } from '../utils/HasTicketsEnabled';
import { BravaCurrencyIcon } from '../widgets/Coins';
import EnabledDropdown from '../widgets/EnabledDropdown';
import Checkbox from '../inputs/Checkbox';
import { Team } from '../../lib/types';
import React from 'react';

const teamSchema = yup
  .object({
    name: yup.string().required(),
    brava_enabled: yup.bool(),
    brava_monthly_allowance: yup.number().nullable().min(0).max(10000000),
    tickets_enabled: yup.bool(),
  })
  .required();

const useTeamUpdateMutation = (teamId: string) => {
  const repo = useRepo();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation(
    (data: Partial<Team>) => {
      return repo.updateTeam(teamId, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('teams');
        queryClient.invalidateQueries(['team', teamId]);
        broadcastSuccessToast(t('settingsSaved'));
      },
    }
  );
};

const TeamSettingsPage = () => {
  return (
    <TeamPageLayout>
      <TeamSettingsPageContent />
    </TeamPageLayout>
  );
};

const TeamSettingsPageContent = () => {
  const { t } = useTranslation();
  const { team } = useTeamPage();
  const mutation = useTeamUpdateMutation(team.id);

  return (
    <>
      <Formik
        initialValues={{
          name: team.name,
          brava_enabled: team.brava_enabled,
          brava_monthly_allowance: team.brava_monthly_allowance ?? null,
          tickets_enabled: team.tickets_enabled,
        }}
        onSubmit={(values, formik) => {
          mutation.mutate(values, {
            onSettled: () => {
              formik.setSubmitting(false);
            },
            onSuccess: () => {
              formik.resetForm({ values });
            },
          });
        }}
        validationSchema={teamSchema}
        validateOnMount
      >
        {({ isValid, isSubmitting, dirty, resetForm, initialValues, values, setFieldValue }) => {
          const canSubmit = !isSubmitting && isValid && dirty;
          const canDiscard = !isSubmitting && dirty;
          const handleDiscard = () => resetForm();

          return (
            <Form className="flex flex-col flex-grow">
              <div className="space-y-8">
                <div className="w-96">
                  <label>
                    <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('name')}</div>
                    <div>
                      <Field as={Input} name="name" />
                    </div>
                  </label>
                </div>
                <HasBravaEnabled>
                  <div className="w-96">
                    <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('brava')}</div>
                    <div>
                      <EnabledDropdown
                        enabled={values.brava_enabled}
                        onChange={(enabled) => setFieldValue('brava_enabled', enabled)}
                      />
                    </div>
                  </div>
                  {values.brava_enabled ? (
                    <div className="w-96">
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('bravaMonthlyTeamAllowance')}</div>
                      <div className="flex gap-2 items-center">
                        <div className="w-32">
                          <Field
                            as={Input}
                            name="brava_monthly_allowance"
                            type="number"
                            value={values.brava_monthly_allowance ?? ''}
                            min={0}
                            max={10000000}
                            disabled={values.brava_monthly_allowance === null}
                          />
                        </div>
                        <div>
                          <BravaCurrencyIcon />
                        </div>
                        <div className="ml-4">
                          <label className="flex items-center text-gray-700 text-sm font-medium">
                            <Field
                              type="checkbox"
                              as={Checkbox}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue(
                                  'brava_monthly_allowance',
                                  e.target.checked ? null : initialValues.brava_monthly_allowance ?? 500
                                )
                              }
                              checked={values.brava_monthly_allowance === null}
                              name="brava_monthly_allowance"
                            />
                            <div className="ml-3">{t('unlimited')}</div>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </HasBravaEnabled>
                <HasTicketsEnabled>
                  <div className="w-96">
                    <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('tickets')}</div>
                    <div>
                      <EnabledDropdown
                        enabled={values.tickets_enabled}
                        onChange={(enabled) => setFieldValue('tickets_enabled', enabled)}
                      />
                    </div>
                  </div>
                </HasTicketsEnabled>
              </div>

              <div className="w-full border-t border-gray-100 mt-10 pt-4 flex flex-row-reverse items-end">
                <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
                  {t('save')}
                </PrimaryButton>
                <Button onClick={handleDiscard} disabled={!canDiscard}>
                  {t('discard')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default TeamSettingsPage;
