import { endOfDay, getUnixTime, startOfDay, subDays } from 'date-fns';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { flavour, flavourHasCompletionTransationFilters, flavourHasEventTransactionFilters } from '../../lib/flavour';
import { useFiltering, useSorting, UseSortingResult } from '../../lib/hooks';
import { ItemType, RedemptionMethod, TransactionType } from '../../lib/types';
import { getTransactionTypeStringKey } from '../../lib/utils';
import SearchInput from '../inputs/Search';
import DateRangePicker from '../widgets/DateRangePicker';
import DropdownSelect, { DropdownSelectOption } from '../widgets/DropdownSelect';
import { hasBravaEnabled, useAccount } from '../../lib/account';

type MaybeDates = (Date | undefined)[];

export const PlayersFiltersContext = React.createContext<{
  filter: {
    filterTerm?: string;
    filterInputProps: any;
  };
  sorting: UseSortingResult;
}>({
  filter: { filterInputProps: {} },
  sorting: {
    sortField: '',
    sortKey: null,
    isDescending: false,
    isKeySelected: () => false,
    onChange: () => {},
  },
});

export const PlayersFiltersProvider: React.FC = ({ children }) => {
  const filter = useFiltering();
  const sorting = useSorting('last_activity', {
    last_activity: true,
    coins: true,
    coins_earned_lifetime: true,
    level: false,
    tickets: true,
  });

  return (
    <PlayersFiltersContext.Provider
      value={{
        filter,
        sorting,
      }}
    >
      {children}
    </PlayersFiltersContext.Provider>
  );
};

export const PlayersFilters: React.FC = () => {
  const {
    filter: { filterInputProps },
  } = useContext(PlayersFiltersContext);
  return <SearchInput {...filterInputProps} />;
};

export const TransactionFiltersContext = React.createContext<{
  hasFilters?: boolean;
  dates: MaybeDates;
  type?: TransactionType;
  details?: string;
  canUseDetails?: boolean;
  timestampFrom?: number;
  timestampTo?: number;

  setDates: (dates: MaybeDates) => void;
  setType: (type?: TransactionType) => void;
  setDetails: (details?: string) => void;
}>({
  dates: [],
  setDates: () => {},
  setType: () => {},
  setDetails: () => {},
});

export const TransactionsFiltersProvider: React.FC = ({ children }) => {
  const [dates, setDates] = useState<MaybeDates>([startOfDay(subDays(new Date(), 7)), endOfDay(new Date())]);
  const [type, setType] = useState<TransactionType>();
  const [details, setDetails] = useState<string>();

  const canUseDetails = type === TransactionType.CoinsGained || type === TransactionType.TicketsGained;
  const timestampFrom = dates[0] ? getUnixTime(dates[0]) : undefined;
  const timestampTo = dates[1] ? getUnixTime(endOfDay(dates[1])) : dates[0] ? getUnixTime(endOfDay(dates[0])) : undefined;
  const hasFilters = [type, details, timestampFrom, timestampTo].some(Boolean);

  return (
    <TransactionFiltersContext.Provider
      value={{
        setDates: setDates,
        setType: setType,
        setDetails: setDetails,
        hasFilters,
        dates,
        type,
        details: canUseDetails ? details : undefined,
        canUseDetails,
        timestampFrom,
        timestampTo,
      }}
    >
      {children}
    </TransactionFiltersContext.Provider>
  );
};

const TRANSACTION_TYPES: {
  id: TransactionType;
  redemption_method?: (method: RedemptionMethod) => boolean;
  item_type?: (type: ItemType) => boolean;
}[] = [
  {
    id: TransactionType.ItemRedeem,
    redemption_method: (method: RedemptionMethod) => method !== RedemptionMethod.None && method !== RedemptionMethod.Self,
  },
  {
    id: TransactionType.ItemPurchase,
    item_type: (type: ItemType) => type === ItemType.Purchase,
  },
  {
    id: TransactionType.ItemSelfRedeem,
    redemption_method: (method: RedemptionMethod) => method === RedemptionMethod.Self,
  },
  {
    id: TransactionType.CoinsGained,
    item_type: (type: ItemType) => false,
  },
  {
    id: TransactionType.CoinsExpired,
    item_type: (type: ItemType) => false,
  },
  {
    id: TransactionType.BidPlaced,
    item_type: (type: ItemType) => type === ItemType.Auction,
  },
  {
    id: TransactionType.BidWon,
    item_type: (type: ItemType) => type === ItemType.Auction,
  },
  {
    id: TransactionType.ContributionMade,
    item_type: (type: ItemType) => type === ItemType.Contribution,
  },
  {
    id: TransactionType.RaffleTicketPurchased,
    item_type: (type: ItemType) => type === ItemType.Raffle,
  },
  {
    id: TransactionType.RaffleWon,
    item_type: (type: ItemType) => type === ItemType.Raffle,
  },
  {
    id: TransactionType.SweepstakesTicketPurchased,
    item_type: (type: ItemType) => type === ItemType.Sweepstakes,
  },
  {
    id: TransactionType.SweepstakesWon,
    item_type: (type: ItemType) => type === ItemType.Sweepstakes,
  },
  {
    id: TransactionType.TicketsGained,
    item_type: (type: ItemType) => false,
  },
];

export const ItemTransactionsFilters = (props: { type: ItemType; redemption: RedemptionMethod }) => {
  const types = useMemo(() => {
    return TRANSACTION_TYPES.filter((type) => {
      const typeTester = type.item_type || (() => true);
      const redemptionTester = type.redemption_method || (() => true);
      return typeTester(props.type) && redemptionTester(props.redemption);
    }).map((t) => t.id);
  }, [props.type, props.redemption]);
  return <TransactionsFiltersBase types={types} />;
};

const TransactionsFilters = () => {
  return <TransactionsFiltersBase types={TRANSACTION_TYPES.map((t) => t.id)} />;
};

const TransactionsFiltersBase = ({ types }: { types: TransactionType[] }) => {
  const { t } = useTranslation();
  const { dates, type, details, canUseDetails, setDates, setType, setDetails } = useContext(TransactionFiltersContext);
  const account = useAccount();

  const typesOptions = useMemo(() => {
    return types
      .map((type) => ({
        label: t(getTransactionTypeStringKey(type)),
        value: type,
      }))
      .sort((i1, i2) => (i1.label < i2.label ? -1 : 1));
  }, [types]); // eslint-disable-line

  const detailsOptions = useMemo(
    () =>
      ([] as { value: string; label: string }[])
        .concat(
          [
            { value: 'manual', label: t('transaction:credit.manual') },
            { value: 'referral', label: t('transaction:credit.friendreferral') },
            { value: 'welcome', label: t('transaction:credit.welcome') },
          ],
          hasBravaEnabled(account)
            ? [
                { value: 'brava', label: t('bravaFilter.any') },
                { value: 'brava-manual', label: t('bravaFilter.manual') },
                { value: 'brava-player', label: t('bravaFilter.player') },
                { value: 'brava-auto', label: t('bravaFilter.auto') },
              ]
            : [],
          flavourHasCompletionTransationFilters
            ? [
                { value: 'activitycompleted', label: t('transaction:credit.activitycompleted') },
                { value: 'coursecompleted', label: t('transaction:credit.coursecompleted') },
              ]
            : [],
          flavourHasEventTransactionFilters
            ? [
                { value: 'chatparticipation', label: t('transaction:credit.chatparticipation') },
                { value: 'chatupvoted', label: t('transaction:credit.chatupvoted') },
                { value: 'checkedin', label: t('transaction:credit.checkedin') },
                { value: 'engagedwithsponsor', label: t('transaction:credit.engagedwithsponsor') },
                { value: 'meetingbooked', label: t('transaction:credit.meetingbooked') },
                { value: 'networking', label: t('transaction:credit.networking') },
                { value: 'profilecompleted', label: t('transaction:credit.profilecompleted') },
                { value: 'recordedcontentviewed', label: t('transaction:credit.recordedcontentviewed') },
                { value: 'registered', label: t('transaction:credit.registered') },
                { value: 'resourcedownloaded', label: t('transaction:credit.resourcedownloaded') },
                { value: 'scavengerhuntcompleted', label: t('transaction:credit.scavengerhuntcompleted') },
                { value: 'sessionattented', label: t('transaction:credit.sessionattented') },
                { value: 'socialattented', label: t('transaction:credit.socialattented') },
              ]
            : []
        )
        .sort((i1, i2) => (i1.label < i2.label ? -1 : 1)),
    [t]
  );

  const selectedType = type ? typesOptions.find((t) => t.value === type) : undefined;
  const selectedDetails = details ? detailsOptions.find((t) => t.value === details) : undefined;

  const handleTypeChange = (type?: DropdownSelectOption) => {
    setType((type?.value as TransactionType) || undefined);
    setDetails(undefined);
  };

  return (
    <div className="flex">
      <div className="flex flex-grow items-center space-x-2">
        <div className="w-48">
          <DropdownSelect
            placeholder={t('typeEllipsis')}
            onChange={handleTypeChange}
            options={typesOptions}
            selected={selectedType}
            clearable
            onClear={handleTypeChange}
          />
        </div>
        {canUseDetails ? (
          <div className="w-48">
            <DropdownSelect
              placeholder={t('awardTypeEllipsis')}
              onChange={(type) => setDetails(type?.value || undefined)}
              options={detailsOptions}
              selected={selectedDetails}
              disabled={!canUseDetails}
              clearable
              onClear={setDetails}
            />
          </div>
        ) : null}
      </div>
      <div>
        <div className="text-sm bg-white border border-gray-300 flex rounded-md overflow-hidden shadow-sm">
          <div className="rounded-bl-md rounded-tl-md flex-grow border-r border-gray-300 bg-gray-50 text-gray-500 py-2 px-3">
            {t('dateRange')}
          </div>
          <div className="flex flex-grow">
            <DateRangePicker value={dates} onChange={setDates} className="flex-grow py-2 px-3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsFilters;
