import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useTeam } from '../../lib/queries';
import { Team } from '../../lib/types';
import TeamNav from '../nav/TeamNav';
import LayoutWithSidebar from './WithSidebar';
import { PageHeaderWrappingNav } from './partials/PageHeaders';

export const TeamPageContext = createContext<{ team: Team }>({
  team: {
    id: 'abc123',
    account_id: 'abc123',
    name: 'Some Team',
    stores: [],
    tickets_enabled: true,
    brava_enabled: false,
    player_count: 0,
  },
});

export const useTeamPage = () => {
  return useContext(TeamPageContext);
};

type TeamLayoutProps = {
  activeNavPill?: string;
  backAction?: () => void;
  buttons?: React.ReactNode | ((team: Team) => React.ReactNode);
};

const TeamPageLayout: React.FC<TeamLayoutProps> = ({ children, buttons, activeNavPill }) => {
  return (
    <TeamPageFreeLayout header={<TeamPageHeader buttons={buttons} activeNavPill={activeNavPill} />}>{children}</TeamPageFreeLayout>
  );
};

const TeamPageHeader = ({ buttons, activeNavPill }: TeamLayoutProps) => {
  const { team } = useTeamPage();
  return (
    <PageHeaderWrappingNav title={team.name} buttons={buttons} buttonsRenderProps={team}>
      <TeamNav team={team} activePill={activeNavPill} />
    </PageHeaderWrappingNav>
  );
};

export const TeamPageFreeLayout: React.FC<{ header: React.ReactNode }> = ({ children, header }) => {
  const { t } = useTranslation();
  const { teamId } = useParams<{ teamId: string }>();
  const { isLoading, data: team } = useTeam(teamId);

  return (
    <LayoutWithSidebar>
      {isLoading || !team ? (
        t('loadingEllipsis')
      ) : (
        <TeamPageContext.Provider value={{ team }}>
          {header}
          {children}
        </TeamPageContext.Provider>
      )}
    </LayoutWithSidebar>
  );
};

export default TeamPageLayout;
