import { ArrowLongDownIcon, PlusIcon } from '@heroicons/react/24/solid';
import { fromUnixTime } from 'date-fns';
import download from 'downloadjs';
import { isUndefined } from 'lodash';
import { ReactNode, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { hasTicketsEnabled, useAccount } from '../../lib/account';
import {
  UseSelectionResult,
  UseSortingResult,
  useManagedFiltering,
  usePermissionChecker,
  useSelection,
  useSorting,
} from '../../lib/hooks';
import { isExpired, isStoreItemContentEditable } from '../../lib/item';
import { ContinuatedQueryObserverResult, useStoreContent } from '../../lib/queries';
import { ContinuableResponse, StoreContentItem, useRepo } from '../../lib/repository';
import { stringSorter } from '../../lib/sort';
import { useStoreItemsFilteringState } from '../../lib/state';
import { Item, ItemSchedule, ItemType, Permission, Product } from '../../lib/types';
import { getItemTypeStringKey } from '../../lib/utils';
import { Button, PrimaryButton } from '../Buttons';
import { ConfirmModal, DuplicateModal } from '../Modals';
import Notification from '../Notifications';
import { HasStorePermission } from '../Permissions';
import { broadcastSuccessToast } from '../Toasts';
import SearchInput from '../inputs/Search';
import { StorePageFreeLayout, StorePageHeader, useStorePage } from '../layouts/StorePage';
import Coins from '../widgets/Coins';
import { DropdownOption } from '../widgets/Dropdown';
import DropdownSelect from '../widgets/DropdownSelect';
import {
  Table,
  TablePagination,
  Tbody,
  Td,
  TdContextualMenu,
  TdPrimary,
  Th,
  ThSortableWithSorting,
  Thead,
  Tr,
} from '../widgets/Table';
import { ItemStatus } from './Item';

const useItemDeleteMutation = (storeId: string) => {
  const queryClient = useQueryClient();
  const repo = useRepo();
  const { t } = useTranslation();
  return useMutation(
    ({ doc_type, id }: StoreContentItem) => {
      if (doc_type === 'product') {
        return repo.deleteProduct(id);
      }
      return repo.deleteItem(id);
    },
    {
      onSuccess: (data, { id, doc_type }) => {
        // TODO Optimistic delete.
        queryClient.invalidateQueries(['store-content', storeId]);
        queryClient.invalidateQueries(['team-market']);
        if (doc_type === 'product') {
          queryClient.removeQueries(['product', id]);
          queryClient.removeQueries(['product-items', id]);
          queryClient.removeQueries(['product-transactions', id]);
        } else {
          queryClient.removeQueries(['item', id]);
          queryClient.removeQueries(['item-transactions', id]);
        }

        broadcastSuccessToast(t('itemDeleted'));
      },
      onError: (err, variables, context: any) => {
        // TODO Display message.
      },
    }
  );
};

const useItemDuplicateMutation = (storeId: string) => {
  const queryClient = useQueryClient();
  const repo = useRepo();
  const { t } = useTranslation();
  return useMutation<Product | Item, unknown, StoreContentItem>(
    ({ doc_type, id, name }: StoreContentItem) => {
      if (doc_type === 'product') {
        return repo.duplicateProduct(id, name);
      }
      return repo.duplicateItem(id, name);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['store-content', storeId]);
        queryClient.invalidateQueries(['team-market']);
        broadcastSuccessToast(t('itemDuplicated'));
      },
      onError: (err, variables, context: any) => {
        // TODO Display message.
      },
    }
  );
};

const useStoreContentExportMutation = (
  orderBy?: string,
  filters?: {
    term?: string;
    type?: ItemType;
    status?: ItemSchedule[];
  }
) => {
  const repo = useRepo();
  const { store } = useStorePage();
  return useMutation(() => repo.exportStoreContent(store.id, filters, orderBy), {
    onSuccess: (data) => {
      download(data, 'store-items.csv', 'text/csv');
    },
  });
};

const useItemsFiltering = (storeId: string) => {
  const { t } = useTranslation();
  const account = useAccount();
  const { type, status, setType, setStatus, searchTerm, setSearchTerm, hasAnyFilters } = useStoreItemsFilteringState(storeId);
  const { filterInputProps } = useManagedFiltering(setSearchTerm, searchTerm);

  const typeOptions = useMemo(() => {
    return (
      [
        ItemType.Purchase,
        ItemType.Raffle,
        ItemType.Auction,
        ItemType.Contribution,

        hasTicketsEnabled(account) ? ItemType.Sweepstakes : null,
      ].filter(Boolean) as ItemType[]
    )
      .map((type) => ({ value: type, label: t(getItemTypeStringKey(type)) }))
      .sort((a, b) => stringSorter(a.label, b.label)) as { value: ItemType; label: string }[];
  }, [t, account]);

  const statusOptions = useMemo(() => {
    return [
      { value: 'active', label: t('active') },
      { value: 'scheduled', label: t('scheduled') },
      { value: 'expired', label: t('expired') },
      { value: 'any', label: t('all') },
    ];
  }, [t]);

  const selectedType = useMemo(() => typeOptions.find((t) => t.value === type), [type, typeOptions]);
  const selectedStatus = useMemo(() => statusOptions.find((s) => s.value === status), [status, statusOptions]);

  const effectiveStatus = useMemo(() => {
    if (status === 'any') {
      return undefined;
    } else if (!status) {
      return ['active', 'scheduled'] as ItemSchedule[];
    }
    return [status] as ItemSchedule[];
  }, [status]);

  return {
    hasAnyFilters,
    filterTerm: searchTerm,
    filterInputProps,

    type,
    selectedType,
    typeOptions,
    onTypeChange: setType,

    status: effectiveStatus,
    selectedStatus,
    statusOptions,
    onStatusChange: setStatus,
  };
};

const StoreItemsPageContent = ({
  query,
  filtering,
  sorting,
}: {
  query: ReturnType<typeof useStoreContent>;
  filtering: ReturnType<typeof useItemsFiltering>;
  sorting: ReturnType<typeof useSorting>;
}) => {
  const { t } = useTranslation();
  const { store } = useStorePage();
  const history = useHistory();
  const storeId = store.id;

  const selection = useSelection(query);
  const [toDeleteItems, setToDeleteItems] = useState<string[]>([]);
  const [toDuplicateItems, setToDuplicateItems] = useState<string[]>([]);
  const deleteMutation = useItemDeleteMutation(storeId);
  const duplicateMutation = useItemDuplicateMutation(storeId);
  const permChecker = usePermissionChecker();

  const handleDelete = () => {
    if (!toDeleteItems.length) {
      return;
    }
    setToDeleteItems([]);

    // This may not scale well for large number of users.
    toDeleteItems.forEach((id) => {
      const contentItem = query.data?.items.find((item) => item.id === id);
      if (!contentItem) {
        selection.removeFromSelection([id]);
        return;
      }

      deleteMutation.mutate(contentItem, {
        onSuccess: () => {
          // The callback will be called for the last registered mutation.
          selection.removeFromSelection(toDeleteItems);
        },
      });
    });
  };

  const handleDuplicate = (name: string) => {
    if (!toDuplicateItems.length) {
      return;
    }
    const itemId = toDuplicateItems[0];
    const contentItem = query.data?.items.find((item) => item.id === itemId);
    setToDuplicateItems([]);

    if (!contentItem) {
      return;
    }

    duplicateMutation.mutate(
      { ...contentItem, name },
      {
        onSuccess: (data) => {
          if (data.doc_type === 'product') {
            history.push(`/product/${data.id}`);
            return;
          }
          history.push(`/item/${data.id}`);
        },
      }
    );
  };

  const getToDuplicateItemName = () => {
    if (!toDuplicateItems.length) return '';
    const itemId = toDuplicateItems[0];
    const contentItem = query.data?.items.find((item) => item.id === itemId);
    return contentItem?.name || '';
  };

  const actions = [
    {
      label: t('edit'),
      can: permChecker.hasStorePermission(store.id, Permission.UpdateItem),
      onClick: (ids: string[]) => {
        const itemId = ids[0];
        const contentItem = query.data?.items.find((item) => item.id === itemId);
        if (!contentItem) return;

        let editUrl = `/item/${itemId}/edit`;
        if (contentItem.doc_type === 'product') {
          editUrl = `/product/${itemId}/edit`;
        }
        history.push(editUrl);
      },
      disabled: (item: StoreContentItem) => !isStoreItemContentEditable(item),
    },
    {
      label: t('duplicate'),
      can: permChecker.hasStorePermission(store.id, Permission.CreateItem),
      onClick: (ids: string[]) => setToDuplicateItems(ids),
    },
    {
      label: t('delete'),
      danger: true,
      can: permChecker.hasStorePermission(store.id, Permission.DeleteItem),
      onClick: (ids: string[]) => setToDeleteItems(ids),
    },
  ].filter((action) => isUndefined(action.can) || action.can);

  const isTotallyEmpty = store.item_count <= 0;

  return (
    <>
      {isTotallyEmpty ? (
        <ContentNoItems />
      ) : (
        <>
          <HasStorePermission storeId={store.id} perm={Permission.ReadStoreTeam}>
            {!store.team_ids.length ? (
              <div className="mb-4">
                <Notification type="warning">
                  <Trans t={t} i18nKey="storeNotLinkedToTeamsVisitPage" components={[<Link to={`/store/${store.id}/teams`} />]} />
                </Notification>
              </div>
            ) : null}
          </HasStorePermission>
          <ItemsFilters {...filtering} />
        </>
      )}

      {!isTotallyEmpty ? (
        <div>
          <ItemsTable actions={actions} queryResult={query} selectionResult={selection} sortingResult={sorting} />
        </div>
      ) : null}

      {toDuplicateItems.length ? (
        <DuplicateModal onConfirm={handleDuplicate} onCancel={() => setToDuplicateItems([])} baseName={getToDuplicateItemName()} />
      ) : null}

      {toDeleteItems.length ? (
        <ConfirmModal
          onConfirm={handleDelete}
          onCancel={() => setToDeleteItems([])}
          title={t('deleteItem', { count: toDeleteItems.length })}
          message={<Trans t={t} i18nKey="deleteItemConfirm" count={toDeleteItems.length} />}
          confirmButtonText={t('delete')}
        />
      ) : null}
    </>
  );
};

const StoreItemsPage = () => {
  return (
    <StorePageFreeLayout header={null}>
      <StoreItemsPageWrapper />
    </StorePageFreeLayout>
  );
};

const StoreItemsPageWrapper = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { store } = useStorePage();
  const sorting = useSorting('name', { name: false });
  const filtering = useItemsFiltering(store.id);
  const filters = useMemo(
    () => ({
      term: filtering.filterTerm,
      type: filtering.type,
      status: filtering.status,
    }),
    [filtering.filterTerm, filtering.type, filtering.status]
  );

  const query = useStoreContent(store.id, sorting.sortField, filters);
  const exportMutation = useStoreContentExportMutation(sorting.sortField, filters);
  const canDownload = query.isSuccess && query.data?.total && query.data.total > 0 && !exportMutation.isLoading;

  return (
    <>
      <StorePageHeader
        activeNavPill="items"
        buttons={(store) => (
          <>
            <Button icon={ArrowLongDownIcon} disabled={!canDownload} onClick={() => exportMutation.mutate()}>
              {t('exportCsv')}
            </Button>
            <HasStorePermission storeId={store.id} perm={Permission.CreateItem}>
              <PrimaryButton onClick={() => history.push(`/store/${store.id}/add-item`)} icon={PlusIcon}>
                {t('addItem')}
              </PrimaryButton>
            </HasStorePermission>
          </>
        )}
      />
      <StoreItemsPageContent query={query} sorting={sorting} filtering={filtering} />
    </>
  );
};

type ItemsTableProps = {
  actions: (Omit<DropdownOption, 'onClick' | 'disabled'> & {
    disabled?: boolean | ((item: StoreContentItem) => boolean);
    onClick: (userId: string[]) => void;
  })[];
  queryResult: ContinuatedQueryObserverResult<ContinuableResponse<StoreContentItem>>;
  sortingResult: UseSortingResult;
  selectionResult: UseSelectionResult;
};

function ItemsTable({ actions, queryResult, selectionResult, sortingResult }: ItemsTableProps) {
  const { t } = useTranslation();
  const { isLoading, isSuccess, data } = queryResult;
  const { onSelectionChange, selectedIds } = selectionResult;
  const nColumns = actions.length ? 4 : 3;

  return (
    <>
      <Table>
        <Thead>
          {/* <ThCheckbox onChange={onSelectAllChange} checked={isEntirePageSelected} /> */}
          <ThSortableWithSorting sortKey="name" sortingResult={sortingResult}>
            {t('name')}
          </ThSortableWithSorting>
          <Th className="w-1/6">{t('type')}</Th>
          <Th>
            <div className="w-full text-center">{t('performance')}</div>
          </Th>
          <Th className="w-1/6">{t('status')}</Th>
          {actions.length ? <Th /> : null}
        </Thead>
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td colSpan={nColumns}>{t('loadingEllipsis')}</Td>
            </Tr>
          ) : null}
          {isSuccess ? (
            !data?.items?.length ? (
              <Tr>
                <Td colSpan={nColumns}>{t('noResultsEllipsis')}</Td>
              </Tr>
            ) : (
              data.items.map((item) => (
                <ItemRow
                  item={item}
                  key={item.id}
                  onSelectChange={() => onSelectionChange(item)}
                  selected={selectedIds.includes(item.id)}
                  actions={actions.map((action) => ({
                    ...action,
                    disabled: typeof action.disabled === 'function' ? action.disabled(item) : action.disabled,
                    onClick: () => action.onClick && action.onClick([item.id]),
                  }))}
                />
              ))
            )
          ) : null}
        </Tbody>
      </Table>
      {isSuccess && (data?.total || 0) > 0 ? (
        <TablePagination
          showingFrom={queryResult.showingFrom}
          showingTo={queryResult.showingTo}
          showingOfTotal={queryResult.showingOfTotal}
          hasNextPage={queryResult.hasNextPage}
          hasPreviousPage={queryResult.hasPreviousPage}
          onNextPageClick={queryResult.fetchNextPage}
          onPreviousPageClick={queryResult.fetchPreviousPage}
        />
      ) : null}
    </>
  );
}

const ItemRow: React.FC<{
  item: StoreContentItem;
  onSelectChange: () => void;
  selected: boolean;
  actions: DropdownOption[];
}> = ({ item, onSelectChange, selected, actions }) => {
  const { t } = useTranslation();
  const src = item.doc_type === 'product' ? item.image_url : item.thumbnail_url || item.image_url;
  const type = item.doc_type === 'product' ? ItemType.Purchase : item.type;
  const to = item.doc_type === 'product' ? `/product/${item.id}` : `/item/${item.id}`;
  return (
    <Tr key={item.id}>
      {/* <TdCheckbox checked={selected} onChange={onSelectChange} centered /> */}
      <TdPrimary to={to}>
        <div className="flex items-center">
          <div className="shrink-0">
            <img src={src} className="w-16 h-16 rounded" alt="" aria-hidden="true" />
          </div>
          <div className="ml-6 whitespace-normal">
            {item.name}
            {'sku' in item ? <div className="font-normal text-xs text-gray-500">{item.sku}</div> : null}
          </div>
        </div>
      </TdPrimary>
      <Td>{t(getItemTypeStringKey(type))}</Td>
      <Td className="h-full text-center">
        <div className="flex justify-center">
          <ItemStats item={item} />
        </div>
      </Td>
      <Td>
        <ItemStatus item={item} />
        {isExpired(item) ? <div className="text-xs mt-2">{fromUnixTime(item.end_time).toLocaleDateString()}</div> : null}
      </Td>
      {actions.length ? <TdContextualMenu options={actions} /> : null}
    </Tr>
  );
};

const ItemStats = ({ item }: { item: StoreContentItem }) => {
  const { t } = useTranslation();

  let title: string | null = null;
  let content: string | number | ReactNode = null;

  if (item.doc_type === 'product' || item.type === ItemType.Purchase) {
    content = item.num_purchased;
    title = t('numberOfPurchases');
  } else if (item.type === ItemType.Raffle || item.type === ItemType.Sweepstakes) {
    title = t('nTicketsSlashnParticipants', {
      tickets: item.num_purchased,
      participants: item.num_participants,
    });
    content = `${item.num_purchased} / ${item.num_participants}`;
  } else if (item.type === ItemType.Auction) {
    title = t('nBidsSlashnParticipants', {
      bids: item.num_bids,
      participants: item.num_participants,
    });
    content = `${item.num_bids} / ${item.num_participants}`;
  } else if (item.type === ItemType.Contribution) {
    title = t('contribution.contributed');
    content = <Coins amount={item.num_coins} />;
  }

  return title ? (
    <abbr title={title} className="cursor-help">
      {content}
    </abbr>
  ) : (
    <>{content}</>
  );
};

const ContentNoItems = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { store } = useStorePage();
  return (
    <div className="flex flex-grow justify-center items-center text-center py-4">
      <div className="max-w-xs pb-10 text-sm">
        <h2 className="font-medium mb-1">{t('noItems')}</h2>
        <p>{t('startByAddingAnItemToThisStore')}</p>
        <div className="mt-6 flex justify-center space-x-4">
          <PrimaryButton large onClick={() => history.push(`/store/${store.id}/add-item`)}>
            {t('addItem')}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

const ItemsFilters = (props: ReturnType<typeof useItemsFiltering>) => {
  const { t } = useTranslation();
  return (
    <div className="mb-4">
      <div className="flex items-center space-x-2">
        {/* <div className="mr-2">
      <Dropdown
        label={t('actions')}
        options={actions.map((action) => ({
          ...action,
          disabled: !selection.selectedIds.length,
          onClick: () => action.onClick(selection.selectedIds),
        }))}
      />
    </div> */}
        <div className="w-96">
          <SearchInput {...props.filterInputProps} />
        </div>
        <div className="w-48">
          <DropdownSelect
            placeholder={t('type')}
            onChange={(o) => props.onTypeChange(o.value as ItemType)}
            options={props.typeOptions}
            selected={props.selectedType}
            clearable
            onClear={() => props.onTypeChange(undefined)}
          />
        </div>

        <div className="w-48">
          <DropdownSelect
            placeholder={t('status')}
            onChange={(o) => props.onStatusChange(o.value as any)}
            options={props.statusOptions}
            selected={props.selectedStatus}
            clearable
            onClear={() => props.onStatusChange(undefined)}
          />
        </div>
      </div>
    </div>
  );
};

export default StoreItemsPage;
