import { format, getUnixTime, isSameDay, isSameMonth, isSameYear } from 'date-fns';
import { TFunction } from 'i18next';

export const FIRST_DAY_OF_WEEK = 1; // Monday.

export function now() {
  return getUnixTime(new Date());
}

export function formatDateRange(start: Date, end: Date) {
  let formatLeft = 'd MMM yyyy';
  let formatRight = 'd MMM yyyy';
  let separator = ' - ';
  if (isSameYear(start, end)) {
    if (isSameMonth(start, end)) {
      formatLeft = 'd';
      separator = '-';
      formatRight = 'd MMM yyyy';
    } else {
      formatLeft = 'd MMM';
    }
  }
  return `${format(start, formatLeft)}${separator}${format(end, formatRight)}`;
}

export function formatTimeAgoShortDuration(t: TFunction, date: Date) {
  const relTime = now() - getUnixTime(date);
  if (relTime < 60) {
    return t('common:reltime.justNow');
  } else if (relTime < 3600) {
    return t('common:reltime.minAgo', { n: Math.floor(relTime / 60) });
  } else if (isSameDay(date, new Date())) {
    return t('common:reltime.hourAgo', { n: Math.floor(relTime / 3600) });
  } else if (isSameYear(date, new Date())) {
    return format(date, 'd MMM');
  }
  return format(date, 'PP');
}
