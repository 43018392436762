import React from 'react';
import { hasBravaEnabled, hasBravaEnabledInTeam, hasTicketsEnabled, hasTicketsEnabledInTeam, useAccount } from '../../lib/account';
import { Team } from '../../lib/types';
import { useTeamPage } from '../layouts/TeamPage';

export const HasBravaEnabled: React.FC<{ not?: boolean }> = ({ children, not }) => {
  const account = useAccount();
  const showContent = not ? !hasBravaEnabled(account) : hasBravaEnabled(account);
  if (!showContent) {
    return null;
  }
  return <>{children}</>;
};
export const HasBravaEnabledInCurrentTeam: React.FC<{ not?: boolean }> = ({ children, not }) => {
  const { team } = useTeamPage();
  return (
    <HasBravaEnabledInTeam not={not} team={team}>
      {children}
    </HasBravaEnabledInTeam>
  );
};
export const HasBravaEnabledInTeam: React.FC<{ team: Team; not?: boolean }> = ({ children, team, not }) => {
  const account = useAccount();
  const showContent = not ? !hasBravaEnabledInTeam(account, team) : hasBravaEnabledInTeam(account, team);
  if (!showContent) {
    return null;
  }
  return <>{children}</>;
};

export const HasTicketsEnabled: React.FC<{ not?: boolean }> = ({ children, not }) => {
  const account = useAccount();
  const showContent = not ? !hasTicketsEnabled(account) : hasTicketsEnabled(account);
  if (!showContent) {
    return null;
  }
  return <>{children}</>;
};

export const HasTicketsEnabledInCurrentTeam: React.FC<{ not?: boolean }> = ({ children, not }) => {
  const { team } = useTeamPage();
  return (
    <HasTicketsEnabledInTeam not={not} team={team}>
      {children}
    </HasTicketsEnabledInTeam>
  );
};

export const HasTicketsEnabledInTeam: React.FC<{ team: Team; not?: boolean }> = ({ children, team, not }) => {
  const account = useAccount();
  const showContent = not ? !hasTicketsEnabledInTeam(account, team) : hasTicketsEnabledInTeam(account, team);
  if (!showContent) {
    return null;
  }
  return <>{children}</>;
};
